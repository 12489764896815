
.PopupWrapper {
  position: fixed;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow: auto;
  padding: 35px;
  background-color: rgba(33, 43, 54, 0.82);
  backdrop-filter: blur(11px);
  background-repeat: repeat;
  &.show {
    z-index: 10;
    opacity: 1;
    pointer-events: auto;
  }
  .main {
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 520px){
    padding: 10px;
    overflow: hidden;
  }
}