.ChangeMode {
  grid-area: changeModeBox;
  background: white;
  box-shadow: -38px 38px 76px -7px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  padding: 10px 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  max-width: 400px;
  justify-self: center;
  width: 100%;

  .setInfoModeBtn, .setSettingModeBtn {
    display: flex;
    height: 38px;
    align-items: center;
    cursor: pointer;
    padding: 0 0 0 10%;
    border-radius: 8px;
    overflow: hidden;
    svg {
      fill: #637381;
    }
    span {
      padding-left: 10px;
    }
    &:hover {
      background-color: rgba(128, 128, 128, 0.06);
    }
    &.active {
      background: #EBF9F2;
      svg {
        fill: #00AB55;
      }
      span {
        color: #00AB55;
      }
      &:hover {
        background: #EBF9F2;
      }
    }
  }
}