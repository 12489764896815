

.MobilChatList {
  .notActiveChat {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: var(--colorGreyText);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .chatList {
    max-width: 450px;
    margin: 0 auto;
    display: none;
    width: calc(100%);
    //position: sticky;
    //top: 125px;
    align-self: start;
    min-height: 448px;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    background: #F9F9F9;
    border: 1px solid #F0F0F0;
    box-shadow: -38px 38px 76px -8px rgba(145, 158, 171, 0.24);
    border-radius: 8px;
    padding: 10px;
    .emptyChatList {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--colorGreyText);
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}