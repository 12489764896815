
.AdminHeader {
  position: sticky;
  z-index: 3;
  top: 0;
  height: 50px;
  border-bottom: rgba(55, 53, 61, 0.16) solid 1px;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  .burgerBtn {
    display: none;
    position: relative;
    margin-left: 20px;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .showBtn, .hideBtn {
      position: absolute;
      //width: 80%;
      visibility: hidden;
      opacity: 0;
      &.active {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s linear 0s;
      }
    }
    .hideBtn {
      width: 80%;
      height: 80%;
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .control {
    padding: 0 20px;
    .logoutBtn {
      background-color: #637381;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .mobileMain {
    //display: none;
    position: absolute;
    left: 0;
    z-index: 1;
    top: calc(-350px);
    background: #FFFFFF;
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    padding: 20px 0;
    opacity: 0;
    &.show {
      top: 50px;
      opacity: 1;
      transition: opacity 0.3s linear 0s;
    }
    .navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      a {
        color: black;
      }
    }
  }
}