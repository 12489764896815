
.StandardButton {
  background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
  box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 46px;
  .main {
    display: flex;
    justify-content: center;
    padding: 10px;
    gap: 7px;
    .svgBox {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      svg {
        width: 100%;
      }
    }
    .textBox {
      font-weight: 700;
      font-size: 14.1429px;
      line-height: 25px;
      color: #FFFFFF;
    }
    img {
      height: 24px;
    }
  }
}