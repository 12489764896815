.InfoTwoPage {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
          'mainBox         mainBox         changeModeBox'
          'mainBox         mainBox         verifyBtnBox'
          'mainBox         mainBox         .';
  align-items: start;
  gap: 40px;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
          'changeModeBox   verifyBtnBox'
          'mainBox         mainBox     ';
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
          'changeModeBox '
          'verifyBtnBox  '
          'mainBox       ';
    gap: 25px;
  }
  .mainBox {
    grid-area: mainBox;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}