*::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(42, 42, 42, 0.32);
  background-color: #f5f5f5;
  //background-color: transparent;
}

*::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  //background-color: #F5F5F5;

}

*::-webkit-scrollbar-thumb
{
  background-color: #919191;
}