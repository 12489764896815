
.GuestTask {
  background: #F9FAFB;
  .container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    .task {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 30px;
      padding-bottom: 30px;
      .taskMain {

        .DoMake {
          font-weight: 700;
          font-size: 35px;
          line-height: 41px;
          color: #212B36;
        }
        .description {
          background: #FFFFFF;
          box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
          border-radius: 7px;
          margin-top: 30px;
          padding: 20px;
          .descriptionTitle {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #212B36;
            margin-bottom: 10px;
          }
        }
        .downloadFileListTitle {
          margin-top: 30px;
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          color: var(--colorBlackText);
        }
        @media screen and (max-width: 1100px){
          order: 2;
        }
      }
      .taskInfo {
        width: 300px;
        .ownerProfile {
          background: #00AB55;
          border: 1px solid #F0F0F0;
          box-shadow: -38px 38px 76px -8px rgba(145, 158, 171, 0.24);
          border-radius: 8px;
          padding: 20px;
          .profile {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 20px;
            color: white;
            .avatar {
              height: 70px;
              width: 70px;
              background-color: #cfd4d9;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              svg {
                width: 60%;
              }
            }
            .nameBox {
              overflow: hidden;
              .name {
                font-weight: bold;
                font-size: 16px;
              }
              .place {
                font-size: 12px;
                color: #e7e7e7;
              }
            }
          }
          .ownerRating {
            margin-top: 20px;
            color: white;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            .ownerRatingTitle {
              font-weight: bold;
            }
            .ownerRatingValue {}
          }
          .countWorkList {
            margin-top: 20px;
            color: white;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            .countWorkListTitle {
              font-weight: bold;
            }
            .countWorkListValue {
              img {
                height: 18px;
              }
            }
          }
          .sendMessageBtn {
            margin-top: 20px;
            height: 50px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: bold;
            font-size: 14px;
            &:hover {
              background: #E0E4E8;
            }
          }

        }
        .countRespond {
          max-width: 300px;
          margin-top: 20px;
          display: flex;
          gap: 20px;
          background: #FFFFFF;
          box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
          border-radius: 7px;
          min-height: 60px;
          padding: 0 20px;
          align-items: center;
          .icon {
            flex: 0 0 34px;
            height: 34px;
            background: #E0E4E8;
            box-shadow: 0px 6px 12px rgba(224, 228, 232, 0.24);
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .countRespondValue {
            font-size: 16px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span {
              font-weight: bold;
            }
            img {
              height: 18px;
            }
          }
          @media screen and (max-width: 420px) {
            max-width: 100%;
          }
        }
        @media screen and (max-width: 1100px){
          margin: 0 auto;
          width: 100%;
          max-width: 600px;
          order: 1;
          .ownerProfile {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 20px;
            .sendMessageBtn {
              margin: 0;
              min-width: 200px;
            }
          }
          .countRespond {
            margin: 20px auto 0;
          }
        }
        @media screen and (max-width: 620px){
          .ownerProfile {
            grid-template-columns: 1fr;
          }
        }
      }
      @media screen and (max-width: 1100px){
        grid-template-columns: 1fr;
      }
    }
  }
}