
.FileList {
  //margin-top: 30px;
  //.title {
  //  font-weight: 600;
  //  font-size: 16px;
  //  line-height: 25px;
  //  color: var(--colorBlackText);
  //}
  .loadingFileList {
    //margin-top: 15px;
    min-height: 30px;
    display: flex;
    align-items: center;
  }
  .emptyFileList {
    //margin-top: 15px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--colorRed);
  }
  .fileList {
    //margin-top: 15px;
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    .addedFileItem {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 82px;
      height: 100px;
      overflow: hidden;
      background-color: var(--backgroundGrayLite);
      border-radius: 5px;
      padding: 8px;
      cursor: pointer;
      gap: 5px;
      div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        font-size: 10px;
      }
      .fileSizeText {
        font-weight: bold;
      }
      .fileImgBox {
        justify-self: center;
        height: 60%;
        width: 60%;
        display: flex;
        justify-content: center;
        background-color: white;
        padding: 3px;
      }
      .upload {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.37);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 60%;
          height: auto;
        }
      }
      .downloadFile {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.37);
      }
      &:hover {
        .downloadFile {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          color: #d7d7d7;
        }
      }
    }
  }

}