.SettingMainSecurity {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  padding: 20px;
  display: flex;
  gap: 40px;
  .securityTitle {
    min-width: 200px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .securityMain {

    .securityPassword {
      display: flex;
      .securityImg {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        background-color: #dcdfe3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .securityPasswordValue {
        margin-left: 10px;
        font-size: 18px;
        .securityPasswordTitle {
          font-weight: bold;
        }
        .hiddenPassword {
          margin-top: 10px;
        }
      }
    }
    .changePasswordBtn {
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      color: var(--colorGreen);
      cursor: pointer;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .securityTitle {
      text-align: center;
    }
    .securityMain {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 30px;
    }
  }
  @media screen and (max-width: 400px) {
    .securityMain {
      flex-direction: column;
      align-items: center;
      .securityPassword {
        .securityPasswordValue {
          display: flex;
          .hiddenPassword {
            margin: 0 0 0 20px;
          }
        }
      }
    }
  }
}