
.ManagerHeader {
  position: sticky;
  z-index: 3;
  top: 0;
  height: 105px;
  background: white;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  border-bottom: rgba(55, 53, 61, 0.16) solid 1px;
  .container {
    height: 100%;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .main {
      height: 100%;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      grid-column-gap: 20px;
      .burgerBtn {
        display: none;
        position: relative;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .showBtn, .hideBtn {
          position: absolute;
          //width: 80%;
          visibility: hidden;
          opacity: 0;
          &.active {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.1s linear 0s;
          }
        }
        .hideBtn {
          width: 80%;
          height: 80%;
        }
        @media screen and (max-width: 767px) {
          display: flex;
        }
      }
      .logo {
        text-decoration: none;
        //padding: 0 10px;
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
        }
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #212B36;
        }
      }
      .navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 0 24px;
        a {
          text-decoration: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          color: #212B36;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .messageNav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        a {
          font-weight: 400;
          font-size: 15px;
          line-height: 26px;
          color: #212B36;
        }
      }
      .searchBtn {
        padding: 5px;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .hintBtn {
        background: #FF8D00;
        box-shadow: 0px 7.54286px 15.0857px rgba(240, 205, 100, 0.24);
        border-radius: 8px;
        padding: 10px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        min-width: 60px;
        span {
          font-weight: 700;
          font-size: 14.1429px;
          line-height: 25px;
          color: #FFFFFF;
        }
      }
      .profileBtn {
        position: relative;
        margin-right: 10px;
        margin-right: 20px;
        .btn {
          cursor: pointer;
        }
        .popup {
          position: absolute;
          right: 0;
          top: 30px;
          //width: 200px;
          //height: 100px;
          background: #FFFFFF;
          border: 1px solid #E3E3E3;
          padding: 15px;
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
          &.show {
            transition: opacity 0.3s;
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
          }
          .profileInfo {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;
            .avatar {
              width: 44px;
              height: 44px;
              background-color: #637381;
              border-radius: 50%;
            }
            .profile {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .name {
                height: 20px;
                white-space: nowrap;
              }
              .email {
                height: 20px;
                white-space: nowrap;
              }
            }
          }
          .messengerBtn {
            margin-top: 20px;
          }
          .showPopupChangePassword {
            margin-top: 10px;
            cursor: pointer;
          }
          a {
            display: block;
            margin-top: 10px;
            color: black;
          }
          .logout {
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .mobileMain {
    //display: none;
    position: absolute;
    left: 0;
    z-index: 1;
    top: calc(-350px);
    background: #FFFFFF;
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    padding: 20px 0;
    opacity: 0;
    &.show {
      top: 80px;
      opacity: 1;
      transition: opacity 0.3s linear 0s;
    }
    .navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      a {
        color: black;
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: 80px;
  }
}
