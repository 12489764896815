
.SocialNetwork {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  //height: 100px;
  padding: 20px;
  .headSocialNetwork {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .socialNetworkTitle {
      font-weight: 700;
      font-size: 18px;
      color: #212B36;
    }
  }
  .mainSocialNetwork {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .addSocialNetwork {
      align-self: flex-start;
      margin-top: 15px;
      display: flex;
      color: var(--colorGreen);
      cursor: pointer;
      font-weight: 600;
      svg {
        margin-right: 10px;
        fill: var(--colorGreen);
      }
    }
  }
}
.SocialNetworkItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  .type {
    position: relative;
    width: 200px;
    height: 34px;
    cursor: pointer;
    .activeType {
      height: 100%;
      align-items: center;
      border-bottom: dashed 1px black;
      display: grid;
      grid-template-columns: auto  1fr ;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        fill: #333333;
        margin: 0 5px;
        width: 12px;
        transform: rotate(-90deg);
      }
    }
    .typeBox {
      display: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 34px;
      background-color: white;
      box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
      border-radius: 7px;
      div {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #efefef;
          cursor: pointer;
        }
      }
    }
    &.showBox {
      .activeType {
        svg {
          transform: rotate(0deg);
        }
      }
      .typeBox {
        display: block;
      }
    }
  }
  .value {
    height: 34px;
    max-width: 350px;
    width: 100%;
    input {
      width: 100%;
      padding-left: 5px;
      height: 100%;
      border-bottom: dashed 1px black;
    }
  }
  .item {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 34px;
    align-items: center;
    .iconBox {
      margin-right: 15px;
      .icon {
        height: 34px;
        width: 34px;

        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 100%;
        }
        &.vk {
          background: linear-gradient(135deg, #90B2E3 0%, #537CBC 100%);
        }
        &.tg {
          background: linear-gradient(135deg, #74CAFF 0%, #0C53B7 100%);
        }
        &.wt {
          background: linear-gradient(135deg, #AAF27F 0%, #229A16 100%);
        }
      }
    }
    .link {

    }
  }
}