
.MyServices {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  //height: 100px;
  padding: 20px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  .iconBox {
    margin-right: 20px;
    width: 24px;
    height: 24px;
    background: #475178;
    box-shadow: 0px 7.69756px 15.3951px rgba(71, 81, 120, 0.1);
    border-radius: 4.52174px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main {
    margin-right: 20px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #212B36;
    }
    .emptyText {
      display: block;
      margin-top: 20px;
    }
  }
}