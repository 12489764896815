.MyWorkOffer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: 20px;
  .main {
    order: 1;
    .showMore {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column: span 3;
      margin-bottom: 20px;
      img {
        height: 24px;
      }
      span {
        height: 40px;
        max-width: 220px;
        width: 100%;
        border: #d5d5d5 solid 1px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
    .loadList {
      max-width: 460px;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 40px;
      }
    }
    .emptyList {
      max-width: 460px;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      font-weight: bold;
    }
    .mitTask {
      margin: 0 0 20px 0;
      background: #FFFFFF;
      box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
      border-radius: 8px;
      padding: 20px;
      cursor: pointer;
      &:hover {
        background: #f3f3f3;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.45), 0px 12px 24px -4px rgba(145, 158, 171, 0.35);
      }
      .head {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        .status {
          display: flex;
          align-items: center;
          color: #637381;
          span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;
            background-color: #637381;
          }
        }
        .createDate {
          text-align: end;
          color: #637381;
          font-weight: bold;
        }
      }
      .minTaskDoMake {
        margin-top: 10px;
        font-weight: bold;
        font-size: 22px;
      }
    }
  }
  @media screen and (max-width: 805px){
    grid-template-columns: 1fr;
    .main {
      order: 3;
    }
  }
}


.Filter {
  order: 2;
  position: sticky;
  top: 120px;
  width: 300px;
  padding: 20px;
  background: #F9F9F9;
  border: 1px solid #F0F0F0;
  box-shadow: -38px 38px 76px -8px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .filterItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 30px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    .filterItemTitle {}
    .filterItemValue {
      height: 30px;
      display: flex;
      align-items: center;
      img {
        height: 70%;
      }
    }
    &:hover {
      background-color: #efeff1;
    }
    &.active {
      background-color: #d4dbe1;
    }
  }
  @media screen and (max-width: 805px){
    position: relative;
    top: 0;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
  }
}