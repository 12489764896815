
.TextField {
  margin-top: 10px;
  textarea {

    height: 31px;
    max-height: 306px;
    width: 100%;
    overflow-y: hidden;
    resize: none;
    line-height: 1.4;
    padding: 5px 5px 5px 0;
    font-size: 15px;
    &::-webkit-scrollbar-button {
      height: 0;
    }
    border-bottom: dashed 1px black;
  }

  .textFieldText {
    white-space: pre;
  }
  .maxLength {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }
}