
.TaskItem {
  min-width: 280px;
  width: 100%;
  max-width: 300px;
  max-height: 450px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.16);
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    min-width: 300px;
  }
  @media screen and (max-width: 375px) {
    min-width: auto;
    max-width: 285px;
    margin: 0 auto;
  }
  &:hover {
    background: rgba(246, 246, 246, 0.09);
  }
  .main {
    position: relative;
    padding: 20px 28px 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto 1fr;
    .createDate {
      justify-self: start;
      height: 30px;
      padding: 0 20px;
      background: #EBF8F1;
      border-radius: 7.3737px;
      display: flex;
      align-items: center;
    }
    .doMake {
      margin-top: 15px;
      font-weight: 700;
      font-size: 18px;
      color: #212B36;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 68px;
    }
    .description {
      margin-top: 15px;
      height: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }
    .borderLine {
      width: calc(100% + 56px);
      position: relative;
      left: -28px;
      margin-top: 10px;
      height: 0px;
      border-bottom: dashed 1px gray;
    }
    .icon {
      width: 35px;
      height: 35px;
      background: #E0E4E8;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .price {
      margin-top: 10px;
      height: 50px;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 10px;
      .priceMain {
        height: 100%;
        color: var(--colorBlackText);
        display: flex;
        align-items: center;
        .priceTitle {
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #212B36;

        }
      }
    }
    .deadline {
      height: 50px;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 10px;

      .deadlineTitle {
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #212B36;

      }
      .deadlineValue {
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #212B36;
      }
    }
  }
  .profile {
    height: 45px;
    width: 100%;
    background-color: #00ab55;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 25px;
    .name {
      height: 100%;
      color: white;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      img {
        margin: 0 auto;
        height: 24px;
      }
    }
  }
}