.InfoPage {
  //overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
          'headSetingPage  headSetingPage  headSetingPage'
          'mainBox         mainBox         changeModeBox'
          'mainBox         mainBox         verifyBtnBox'
          'mainBox         mainBox         .';
  align-items: start;
  gap: 40px;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
          'headSetingPage  headSetingPage'
          'changeModeBox   verifyBtnBox'
          'mainBox         mainBox     ';
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
          'changeModeBox '
          'headSetingPage'
          'verifyBtnBox  '
          'mainBox       ';
    gap: 25px;
  }
  .mainBox {
    grid-area: mainBox;
    display: flex;
    flex-direction: column;
    gap: 30px;
    iframe {
      background: #FFFFFF;
      box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
      border-radius: 7px;
    }
  }
}