
.ArbitrageItem {
  .container {
    height: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 10px 30px;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 30px;
    .main {
      .taskInfo {
        .DoMake {
          font-weight: 700;
          font-size: 24px;
          color: #212B36;
        }
        .description {
          margin-top: 10px;
        }
        .deadlineAndPrice {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px;
          border-bottom: dashed 1px gray;
          padding: 10px 0;
          .price {
            display: flex;
            gap: 10px;
            .priceTitle {
              font-weight: bold;
            }
          }
          .deadline {
            display: flex;
            gap: 10px;
            .deadlineTitle {
              font-weight: bold;
            }
          }
        }
      }
      .lineDashed {
        margin: 15px 0;
        border-bottom: dashed 1px black;
      }
      .arbitrageItemList {
        margin-top: 20px;
        .arbitrageItemListTitle {
          font-size: 28px;
          font-weight: bold;
        }
      }
    }

    .info {
      align-self: start;
      position: sticky;
      top: 125px;
      max-width: 300px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
      .status {
        width: 100%;
        max-width: 300px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 10px;
        background: #F9F9F9;
        border: 1px solid #F0F0F0;
        box-shadow: -38px 38px 76px -7px rgba(145, 158, 171, 0.24);
        border-radius: 7px;
        padding: 15px;
        @media screen and (max-width: 650px){
          max-width: 472px;
        }
        @media screen and (max-width: 500px){
          max-width: 100%;
        }
        .statusTitle {
          font-weight: bold;
          font-size: 16px;
        }
        .statusValue {
          justify-self: end;
          width: 100%;
          max-width: 250px;
          min-height: 30px;
          height: 100%;
          .statusWorked {
            height: 100%;
            background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: bold;
          }
          .statusTest {
            height: 100%;
            background: linear-gradient(135deg, #FFA48D 0%, #FF4842 100%);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: bold;
          }
          .statusFinish {
            height: 100%;
            background: linear-gradient(135deg, #5BE584 0%, #007B55 100%);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: bold;
          }
          .statusArbitrage {
            height: 40px;
            background: linear-gradient(135deg, #B8C4CE 0%, #7C90A0 100%);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      .fileList {
        width: 100%;
        max-width: 300px;
        background: #F9F9F9;
        border: 1px solid #F0F0F0;
        box-shadow: -38px 38px 76px -7px rgba(145, 158, 171, 0.24);
        border-radius: 7px;
        overflow: hidden;
        @media screen and (max-width: 650px){
          max-width: 472px;
        }
        @media screen and (max-width: 500px){
          max-width: 100%;
        }
        .showFileListBtn {
          padding: 25px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          font-weight: bold;
          .fileListBtnIcon {
            transform: rotate(180deg);
            &.show {
              transform: rotate(0deg);
            }
          }
        }
        .fileBox {
          height: 0;
          overflow: hidden;
          padding: 0 25px;
          &.show {
            height: auto;
            padding-bottom: 25px;
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      .main {
        order: 2;
      }
      .info {
        position: relative;
        top: 0;
        margin: 0 auto;
        order: 1;
        max-width: 500px;
        .status {
          max-width: 500px;
        }
        .fileList {
          max-width: 500px;
        }
      }
    }
  }
}
.Appeal {
  .head {
    //height: 90px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 20px;
    background: rgb(71,81,120);
    background: linear-gradient(0deg, rgb(255, 255, 255) 25%, rgba(71,81,120,1) 25%);
    padding: 15px 20px;
    border-radius: 16px 16px 0 0;
    color: white;
    .avatar {
      height: 75px;
      width: 75px;
      border-radius: 50%;
      background-color: #cfd4d9;
      border: 4px white solid;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      svg {
        width: 60%;
      }
    }
    .profileMain {
      overflow: hidden;
      //margin-top: 10px;
      opacity: 1;
      align-self: start;
      &.fetch {
        opacity: 0;
      }
      .name, .place, .profileType {
        height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .profileType {
        text-transform: uppercase;
        color: #01c964;
        font-weight: bold;
      }
      .name {
        font-weight: bold;
      }
    }

    @media screen and (max-width: 500px){
      grid-template-columns: 1fr;
      background: linear-gradient(0deg, rgba(71,81,120,0) 0%, rgba(71,81,120,1) 0%);
      border-radius: 16px;
      padding: 15px 15px;
      .profileMain {
        margin-top: 0;
      }
    }
  }
  .appealMain {
    grid-column: span 2;
    //height: 100px;
    background-color: white;
    padding: 0 20px 20px;
    border-radius: 0 0 16px 16px;

    .appealInner {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .type {
        min-height: 50px;
        max-width: 300px;
        width: 100%;
        background-color: #a6a6a6;
        padding: 15px;
        color: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .percent {
        max-width: 300px;
        width: 100%;
        border-radius: 8px;
        border: #37353D solid 1px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          padding: 2px 5px;
          margin-left: 5px;
          background-color: rgba(0, 171, 85, 0.24);
          border-radius: 5px;
        }
      }
      @media screen and (max-width: 600px) {
        padding-top: 20px;
      }
    }
    .comment {
      margin-top: 20px;
      span {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
}
.Decision {
  //margin-top: 15px;
  .decisionTitle {
    font-size: 28px;
    font-weight: bold;
  }
  .answerText {
    margin-top: 20px;
    textarea {
      width: 100%;
      height: 100px;
      resize: none;
      padding: 10px;
    }
  }
  .btnBox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    .percent {
      display: grid;
      grid-template-columns: auto 150px;
      align-items: center;
      gap: 20px;
      .percentTitle {
        font-weight: bold;
      }
      .percentBtn {
        position: relative;
        .percentValue {
          height: 34px;
          width: 100%;
          background: #EBF8F1;
          border: 1px solid #F0F0F0;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          cursor: pointer;
          svg {
            fill: #637381;
            transform: rotate(0deg);
            &.show {
              transform: rotate(-180deg);
            }
          }
        }
        .percentBox {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 38px;
          width: 100%;
          background-color: white;
          border: rgba(99, 115, 129, 0.44) solid 1px;
          border-radius: 8px;
          //height: 180px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          span {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
              background-color: #eeeeee;
            }
          }
          &.show {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
    .sendBtn {
      height: 40px;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
      box-shadow: 0px 6.79503px 13.5901px rgba(111, 147, 241, 0.24);
      border-radius: 6.79503px;
      color: white;
      cursor: pointer;
    }

  }
  @media screen and (max-width: 600px) {
    .decisionTitle {
      text-align: center;
    }
    .btnBox {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}