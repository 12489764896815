
.ToggleBtn {
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .toggle {
    position: relative;
    width: 60px;
    background: #EEF0F4;
    border-radius: 55px;
    height: 31px;
    &:before{
      content: '';
      position: absolute;
      top: 3px;
      right: 3px;
      left: auto;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #00AB55;
      box-shadow: 0px 8px 16px rgba(0, 171, 85, 0.24);
    }
    &.checked:before {
      left: 3px;
      right: auto;
      background-color: white;
      box-shadow: none;
    }
  }
  .text {
    padding-left: 15px;
    @media screen and (max-width: 520px) {
      display: none;
    }
  }
}