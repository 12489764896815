
.ErrorServer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    .title {
      padding: 0 5px;
    }
    .refreshBtn {
      margin-top: 20px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
      box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
      border-radius: 8px;
      color: white;
      cursor: pointer;
    }
  }
}