
.TopUp {
  align-self: start;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 270px;
  .title {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  input {
    margin-top: 15px;
    height: 40px;
    width: 100%;
    max-width: 300px;
    background: #FFFFFF;
    border: 1.0452px solid #E9EEFB;
    border-radius: 8.36158px;
    padding-left: 10px;
  }
  button {
    background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
    box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
    border-radius: 8px;
    color: white;
    height: 40px;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 24px;
    }
  }
  @media screen and (max-width: 991px) {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
}
