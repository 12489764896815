
.LoadingApp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 10;
  color: black;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .text {
      font-size: 16px;
      font-weight: bold;
    }
    .loader {
      img {
        height: 24px;
      }
    }
  }
}