.AddFaq {
  background-color: #f1f1f1;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 15px;
    .addBlock {
      background-color: white;
      min-height: 100px;
      border-radius: 8px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .title {
        color: #727273;
        text-align: center;
        font-size: 18px;
      }
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        max-width: 600px;
        textarea {
          height: 100px;
          width: 100%;
          resize: none;
          box-shadow: 2px 2px 5px 2px rgba(84, 84, 84, 0.31);
          padding: 10px;
          &:first-child {
            height: 52px;
          }
        }
      }
      button {
        max-width: 200px;
        width: 100%;
        padding: 10px 20px;
        border-radius: 8px;
        height: 40px;
        img {
          height: 22px;
        }
      }
    }
  }
}
