
.DoMake {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 800px;
  width: 100%;
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #212B36;
  }
}