
.CloseBtn {
  position: absolute;
  right: -24px;
  top: -24px;
  width: 24px;
  height: 24px;
  opacity: 0.3;
  @media screen and (max-width: 520px){
    right: 14px;
    top: 14px;
    z-index: 1;
  }
}
.CloseBtn:hover {
  opacity: 1;
  cursor: pointer;
}
.CloseBtn:before, .CloseBtn:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 24px;
  width: 2px;
  background-color: white;
  @media screen and (max-width: 520px){
    background-color: black;
  }
}
.CloseBtn:before {
  transform: rotate(45deg);
}
.CloseBtn:after {
  transform: rotate(-45deg);
}

