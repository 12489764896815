
.LoadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .text {
      font-size: 16px;
      font-weight: bold;
    }
    .loader {
      img {
        height: 24px;
      }
    }
  }
}
