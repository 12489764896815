
.VerifyMessageBlock {
  background: #00AB55;
  border: 1px solid #F0F0F0;
  box-shadow: -38px 38px 76px -8px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 15px 0;
  color: white;
  font-weight: 700;
  font-size: 16px;
  .rating {
    padding: 0 35px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    border-bottom: 1px dashed #D8D8D8;
    align-items: center;
  }
  .info {
    padding: 0 35px;
    display: grid;
    grid-template-rows: auto auto;

    .taskCount {
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      img {
        height: 18px;
      }
    }
    .successTask {
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      img {
        height: 18px;
      }
    }
  }
  .btnBox {
    padding: 0 35px;
    margin: 20px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    color: black;
    .offerAnOrderBtn,.sendMessage {
      height: 50px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
      font-size: 14px;
      &:hover {
        background: #E0E4E8;
      }
    }
  }
}