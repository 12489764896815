
.Head {
  grid-area: headSetingPage;
  border-radius: 16px;

  overflow: hidden;
  .changeBtnBox {
    padding-top: 15px;
    background-color: var(--backgroundGrayBlue);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .changeBtn {
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: var(--colorWhiteLightText);
      cursor: pointer;
      width: 178px;
      svg {
        fill: var(--colorWhiteLightText);
      }
      span {
        padding-left: 10px;
        padding-bottom: 4px;
      }
      &:hover {
        svg {
          fill: #efefef;
        }
        span {
          color: #efefef;
        }
      }
      &.active {
        svg {
          fill: white;
        }
        span {
          color: white;
        }
      }
      @media screen and (max-width: 767px) {
        width: auto;
      }
    }
  }

  .firstRow {
    min-height: 140px;
    background-color: var(--backgroundGrayBlue);
    padding: 15px 30px 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap-reverse;
    color: white;
    @media screen and (max-width: 767px) {
      gap: 20px;
      padding: 10px 20px;
    }
    @media screen and (max-width: 375px) {
      gap: 15px;
      padding: 10px 10px;
    }
    .name {
      min-height: 80px;
      position: relative;
      padding-left: 180px;
      display: flex;
      align-items: flex-end;
      min-width: 400px;
      .avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        padding: 4px;
        background-color: white;
        .imgBox {
          width: 100%;
          height: 100%;
          background-color: #cfd4d9;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          svg {
            width: 60%;
          }
        }
        .changeAvatarBtn {
          color: var(--colorGreyText);
          position: absolute;
          left: 0;
          right: 0;
          bottom: -20px;
          text-align: center;
          cursor: pointer;
          input {
            width: 0;
            pointer-events: none;
          }
          .mobilChangeAvatarBtn {
            display: none;
          }
        }
      }
      .nameValue {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;
        .nameTitle {
          opacity: 0.72;
        }
        .nameText {
          margin-top: 5px;
          font-weight: 700;
          font-size: 30px;
          line-height: 28px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        input {
          font-weight: bold;
          font-size: 30px;
          background-color: var(--backgroundGrayBlue);
          color: white;
          border: 1px dashed #bdbdbd;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        min-height: 30px;
        padding-left: 100px;
        min-width: auto;
        .avatar {
          position: absolute;
          left: 0;
          top: 0;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          padding: 4px;
          background-color: white;
          .imgBox {
            width: 100%;
            height: 100%;
            background-color: #637381;
            border-radius: 50%;
            overflow: hidden;
          }
          .changeAvatarBtn {
            top: 4px;
            left: 4px;
            bottom: 4px;
            right: 4px;
            background-color: rgba(0, 0, 0, 0.52);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: none;
            }
            .mobilChangeAvatarBtn {
              display: block;
              font-size: 42px;
              color: #b9b9b9;
            }
            &:hover {
              .mobilChangeAvatarBtn {
                color: white;
              }
            }
          }
        }
        .nameValue {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          overflow: hidden;
          .nameTitle {
            opacity: 0.72;
          }
          .nameText {
            margin-top: 5px;
            font-weight: 700;
            font-size: 24px;
            line-height: 1;
          }
          input {
            font-size: 24px;
          }
        }
      }
      @media screen and (max-width: 375px) {
        .nameValue {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .nameTitle {
            opacity: 0.72;
          }
          .nameText {
            margin-top: 5px;
            font-weight: 700;
            font-size: 20px;
          }
          input {
            font-size: 20px;
          }
        }
      }
    }
    .cro {
      display: flex;
      flex-direction: column;
      gap: 20px;
      min-width: 400px;
      font-size: 14px;
      @media screen and (max-width: 600px) {
        min-width: auto;
      }
      .croName {
        .croNameTitle {
          opacity: 0.72;
        }
        .croNameText {
          margin-top: 5px;
          font-weight: 600;
        }
      }
      .croNumber {
        .croNumberTitle {
          opacity: 0.72;
        }
        .croNumberText {
          margin-top: 5px;
          font-weight: 600;
        }
      }
    }
    .status {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 14px;
      @media screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
      .registeredDate {
        .registeredDateTitle {
          opacity: 0.72;
        }
        .registeredDateText {
          margin-top: 5px;
          font-weight: 600;
        }
      }
      .online {
        .onlineTitle {
          opacity: 0.72;

        }
        .onlineText {
          margin-top: 5px;
          height: 18px;
          .onlineStatus {
            font-weight: bold;
            display: flex;
            align-items: center;
            .on {
              margin-right: 8px;
              width: 6px;
              height: 6px;
              background: linear-gradient(135deg, #5BE584 0%, #007B55 100%);
              outline: 3px solid rgba(36, 165, 104, 0.3);
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
  .secondRow {
    min-height: 75px;
    background-color: white;
    padding: 10px 10px 10px 210px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      padding: 10px;
      .phone,.email {
        width: 100%;
        justify-content: space-between;
        overflow: hidden;
      }
    }
    .phone {
      flex: 1 0 calc(50% - 10px);
      display: flex;
      .phoneMain {
        .phoneTitle {
          font-weight: 700;
          font-size: 16px;
        }
        .phoneText {
          margin-top: 5px;
        }
        input {
          margin-top: 5px;
          border: 1px dashed #bdbdbd;
        }
      }
    }
    .email {
      flex: 1 0 calc(50% - 10px);
      display: flex;
      .emailMain {

        .emailTitle {
          font-weight: 700;
          font-size: 16px;
        }
        .emailText {
          margin-top: 5px;
        }
      }
    }
    .changeShowBtn {
      margin-left: 20px;
      display: flex;
      align-items: center;
    }
  }
}