
.ExecutorItem {
  min-width: 280px;
  width: 100%;
  max-width: 300px;
  min-height: 450px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.16);
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    min-width: 300px;
  }
  @media screen and (max-width: 375px) {
    min-width: auto;
    max-width: 285px;
    margin: 0 auto;
  }
  &:hover {
    background: #f6f6f6;
  }
  &.disable {
    &:hover {
      background: #FFFFFF;
    }
  }
  .main {
    position: relative;
    display: grid;
    padding: 20px 28px 20px;
    @media screen and (max-width: 375px) {
      padding: 20px 20px 20px;
    }
    .onlineLoading {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 20px;
    }
    .onlineStatus {
      position: absolute;
      right: 10px;
      top: 10px;
      font-weight: bold;
      display: flex;
      align-items: center;
      .on {
        margin-right: 8px;
        width: 6px;
        height: 6px;
        background: linear-gradient(135deg, #5BE584 0%, #007B55 100%);
        outline: 3px solid rgba(36, 165, 104, 0.3);
        border-radius: 8px;
      }
      .off {
        margin-right: 8px;
        width: 6px;
        height: 6px;
        background: linear-gradient(135deg, #FFA48D 0%, #FF4842 100%);
        outline: 3px solid rgba(234, 142, 119, 0.42);
        border-radius: 8px;
      }
    }
    .avatar {
      width: 75px;
      height: 75px;
      background-color: #cfd4d9;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      svg {
        width: 60%;
      }
    }
    .prof {
      margin-top: 12px;
      font-weight: 700;
      font-size: 18px;
      line-height: 114%;
      color: #212B36;
    }
    .place {
      margin-top: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 108%;
      color: #637381;
    }
    .text {
      height: 74px;
      margin-top: 15px;
      font-weight: 300;
      font-size: 14px;
      line-height: 133%;
      color: #637381;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .info {

      align-self: end;
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px gray dotted;
      .addressBox {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        .icon {
          width: 35px;
          height: 35px;
          background: #E0E4E8;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .address {
          font-weight: 300;
          font-size: 16px;
          line-height: 110%;
          color: var(--colorBlackText);
        }
      }
      .emailBox {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        .icon {
          width: 35px;
          height: 35px;
          background: #E0E4E8;
          border-radius: 6px;
        }
        .email {
          display: grid;
          grid-template-columns: 1fr;
          .emailTitle {
            font-weight: 700;
            font-size: 16px;
            line-height: 114.02%;
            color: #212B36;
          }
          .emailText {
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .activeTaskCountBox {
        margin-top: 20px;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        .icon {
          width: 35px;
          height: 35px;
          background: #E0E4E8;
          border-radius: 6px;
        }
        .activeTaskCount {
          display: grid;
          grid-template-columns: 1fr;
          .activeTaskCountTitle {
            font-weight: 700;
            font-size: 16px;
            line-height: 114.02%;
            color: #212B36;
          }
          .activeTaskCountText {
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex;
            align-items: center;
            img {
              height: 16px;
            }
          }
        }
      }
    }

  }
  .name {
    height: 40px;
    background: #00AB55;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 0 10px 0 20px;
    .text {
      font-weight: 700;
      font-size: 14px;
      line-height: 114%;
      color: #FFFFFF;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .rating {

    }
  }
}