
.Hint {
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--backgroundOpacity);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    min-width: 250px;
    margin: 20px;
    padding: 20px 30px;
    max-width: 500px;
    background: #FFFFFF;
    box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    .text {
      margin-top: 10px;
      text-align: center;
      color: var(--colorBlackText);
      font-weight: 700;
      font-size: 14.4329px;
      line-height: 17px;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      .iconHint {
        margin: 0 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &.ok {
          background: #00AB55;
          box-shadow: 0px 6px 12px rgba(0, 171, 85, 0.24);
        }
        &.warning {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #deec10;
          box-shadow: 0px 6px 12px rgba(208, 182, 13, 0.24);
        }
      }
    }
    .closeBtn {
      cursor: pointer;
      margin: 20px auto 0;
      max-width: 150px;
      width: 100%;
      height: 40px;
      background: var(--backgroundGradientBlue);
      box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
      color: white;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    }
  }
}