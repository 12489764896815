
.Messenger {
  //min-height: 400px;
  //max-height: 150vh;
  //max-height: calc(100vh - 140px);
  .container {
    max-width: 1220px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 10px 10px;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 30px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr
    }
  }
  .chatList {
    width: calc(100%);
    //position: sticky;
    //top: 125px;
    align-self: start;
    min-height: 50px;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    background: #F9F9F9;
    border: 1px solid #F0F0F0;
    box-shadow: -38px 38px 76px -8px rgba(145, 158, 171, 0.24);
    border-radius: 8px;
    padding: 10px;
    .addChatListBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      img {
        height: 24px;
      }
    }
    .emptyChatList {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--colorGreyText);
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}