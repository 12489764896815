
.MyWork {
  background: #F9FAFB;
  .container {
    height: 100%;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    .changePage {
      margin-top: 30px;
      display: flex;
      //justify-content: ;
      align-items: center;
      gap: 20px;
      .taskBtn,.offerBtn {
        height: 40px;
        width: 220px;
        background: #DDE0E8;
        border-radius: 8px;
        color: #8A93A9;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          background: #cbccd0;
        }
        &.active {
          background: #637381;
          color: white;
        }
      }
      @media screen and (max-width: 805px){
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        .taskBtn,.offerBtn {
          margin: 0;
        }
      }
      @media screen and (max-width: 487px){
        .taskBtn,.offerBtn {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}