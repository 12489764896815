
.VerificationList {
  .container {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px 10px;

    //.search {
    //  margin-top: 10px;
    //  background: #EEF0F4;
    //  border-radius: 8px;
    //  padding: 30px;
    //
    //  .inputBox {
    //    position: relative;
    //    z-index: 1;
    //    height: 46px;
    //    border-radius: 8px;
    //    overflow: hidden;
    //
    //    input {
    //      height: 100%;
    //      width: 100%;
    //      background: #FFFFFF;
    //      padding-left: 15px;
    //    }
    //
    //    .searchBtn {
    //      position: absolute;
    //      right: 5px;
    //      top: 50%;
    //      transform: translateY(-50%);
    //      cursor: pointer;
    //      padding: 7px 10px;
    //      border-radius: 6px;
    //      display: flex;
    //      align-items: center;
    //
    //      &:hover {
    //        background-color: #EEF0F4;
    //      }
    //    }
    //  }
    //}
  }

  .list {
    padding: 10px 0 0;
  }

  .loadingList {
    display: flex;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
      margin-top: 50px;
    }
  }

  .emptyList {

  }
  .showMore {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 3;
    img {
      height: 24px;
    }
    span {
      height: 40px;
      max-width: 220px;
      width: 100%;
      border: #d5d5d5 solid 1px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.VerifyItem {
  margin-bottom: 15px;
  padding: 10px 20px;
  min-height: 50px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 20px;
  background-color: white;

  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  .name {
    overflow: hidden;
    @media screen and (max-width: 767px) {
      grid-column: span 3;
    }
    .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .status {
    @media screen and (max-width: 550px) {
      grid-column: span 3;
    }
  }
}