
.SaveChangeBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;

  .saveBtn,.cancelBtn {
    height: 50px;
    width: 220px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    color: var(--colorGreyText);
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.13);
    &:hover {
      color: var(--colorBlackText);
      background-color: #dadada;
    }
    img {
      height: 60%;
    }
  }
  .saveBtn {
    &:hover {
      color: white;
      background-color: var(--backgroundGreen);
    }
  }
}