
.ArbitrageList {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px 15px;

  .arbitrageListTitle {
    margin-top: 20px;
    font-size: 22px;
    font-weight: bold;
  }
  .arbitrageListMain {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    .loadingList {
      margin: 50px auto 0;
      height: 50px;
    }
    .taskItem {
      width: 100%;
      padding: 20px;
      min-height: 50px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      background: #eaeaea;
      box-shadow: 0px 0px 2px rgba(250, 250, 250, 0.2), 0px 12px 24px -4px rgba(255, 255, 255, 0.12);
      border-radius: 7px;
      &:hover {
        background: #FFFFFF;
        cursor: pointer;
      }
    }
  }
  .showMore {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 3;
    img {
      height: 24px;
    }
    span {
      height: 40px;
      max-width: 220px;
      width: 100%;
      border: #d5d5d5 solid 1px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
