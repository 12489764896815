
.PlaceExecutor {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  //height: 100px;
  padding: 20px;
  .headPlaceExecutor {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .placeExecutorTitle {
      font-weight: 700;
      font-size: 18px;
      color: #212B36;
    }
  }
  .mainPlaceExecutor {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .regionPlace, .cityPlace {
      width: 100%;
      max-width: 250px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #E9EEFB;
      border-radius: 8.36158px;
    }
  }
}