
.FaqPage {

  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px 40px;
    .title {
      margin-top: 30px;
      font-weight: 700;
      font-size: 35px;
      line-height: 41px;
      color: #212B36;
    }
    .faqList {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .loadingList {
        align-self: center;
        margin-top: 20px;
        height: 50px;
        img {
          height: 100%;
        }
      }
    }
  }
}
.FaqItem {
  background: #FFFFFF;
  border: 1.0452px solid #E9EEFB;
  border-radius: 8.36158px;
  cursor: pointer;
  .faqItemAsk {
    padding: 10px 20px;
    min-height: 50px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    line-height: 1.4;
    span {
      overflow: hidden;
      word-wrap: anywhere;
    }
    svg {
      transform: rotate(180deg);
    }
    &.active {
      svg {
        transform: rotate(0deg);
      }
    }
  }
  .faqItemAnswer {
    padding: 10px 20px 20px;
    white-space: pre-wrap;
    display: none;
    cursor: default;
    &.show {
      display: block;
    }
  }
  .deleteBtn {
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #c40404;
    max-width: 200px;
    color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    img {
      height: 22px;
    }
  }
}