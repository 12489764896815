.setPlaceInputBox {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  .placeRegion,.placeCity {
    position: relative;
    width: 100%;
    min-width: 240px;
    max-width: 280px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E9EEFB;
    border-radius: 8.36158px;
    padding: 0 0 0 15px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    cursor: pointer;
    input {
      height: 100%;
      width: 100%;
    }
    .placeRegionBtn, .placeCityBtn {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #E9EEFB;
      height: 70%;
      svg {
        transform: rotate(0deg);
      }
    }
    &.active {
      .placeRegionBtn, .placeCityBtn {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .placeRegionBox, .placeCityBox {
      display: none;
      position: absolute;
      z-index: 1;
      top: 44px;
      left: 0;
      width: 100%;
      min-height: 90px;
      max-height: 220px;
      background: #FFFFFF;
      border: 1.0452px solid #E9EEFB;
      border-radius: 8.36158px;
      overflow-y: scroll;
      overflow-x: hidden;
      .clearRegionBtn,.clearCityBtn {
        text-align: center;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 56, 253, 0.04);
        &:hover {
          background-color: rgba(0, 56, 253, 0.09);
        }
      }
      .emptyRegionList {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .placeBoxItem {
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        color: #37353D;
        &:hover {
          background-color: #ececec;
          color: black;
        }
      }
      &.show {
        display: block;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
        width: 8px;
        background-color: transparent;
        -webkit-box-shadow: none;
      }
      &::-webkit-scrollbar {
        border-radius: 8px;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        //width: 10px;
        background-color: rgba(99, 115, 129, 0.42);
      }
      @media screen and (max-width: 500px){
        height: 280px;
      }
    }
  }
}