
.OfferItem {
  margin-bottom: 30px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.16);
  border-radius: 8px;

  .respondProfile {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 20px;
    .avatar {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      background-color: #cfd4d9;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      svg {
        width: 60%;
      }
    }
    .profileMain {
      opacity: 1;
      &.fetch {
        opacity: 0;
      }
      .name, .place, .rating {
        height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .name {
        font-weight: bold;
      }
    }
    @media screen and (max-width: 550px){
      grid-template-columns: 1fr;
    }
  }
  .respondPrice {
    margin-top: 15px;
    font-weight: bold;
    span {
      font-weight: 400;
    }
  }
  .respondDeadline {
    margin-top: 15px;
    font-weight: bold;
    span {
      font-weight: 400;
    }
  }

  .respondMain {
    margin-top: 20px;
    white-space: pre-wrap;
    max-height: 185px;
    overflow-y: auto;
    background-color: #f5f4f4;
    padding: 10px;
  }
  .respondBtnBox {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    color: white;
    &.fetch {
      opacity: 0.5;
    }

    .sendMessageBtn {
      height: 50px;
      max-width: 200px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      background: #00AB55;
      box-shadow: 0px 4px 4px rgba(0, 171, 85, 0.24);
      border-radius: 8px;
      cursor: pointer;
      @media screen and (max-width: 550px){
        max-width: 100%;
      }
    }

    .selectBtn {
      height: 50px;
      max-width: 200px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
      box-shadow: 0px 4px 4px rgba(111, 147, 241, 0.24);
      border-radius: 8px;
      cursor: pointer;
      img {
        height: 70%;
      }
      @media screen and (max-width: 550px){
        max-width: 100%;
      }
    }
    .deselectBtn {
      height: 50px;
      max-width: 200px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      background: linear-gradient(135deg, #ff8484 0%, #b71919 100%);
      box-shadow: 0px 4px 4px rgba(111, 147, 241, 0.24);
      border-radius: 8px;
      cursor: pointer;
      img {
        height: 70%;
      }
      @media screen and (max-width: 550px){
        max-width: 100%;
      }
    }
  }
}