
.SettingMainPlace {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  //height: 100px;
  padding: 20px;
  .headPlace {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .placeTitle {
      font-weight: 700;
      font-size: 18px;
      color: #212B36;
    }
    .showToggleBox {}
  }
  .mainPlace {
    max-width: 580px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .regionInputBox, .cityInputBox {
      position: relative;
      height: 40px;

      //overflow: hidden;
      width: 100%;
      max-width: 280px;
      padding-left: 10px;
      background: #FFFFFF;
      border: 1px solid #E9EEFB;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }
    .regionInputBox {
      &.focus {
        .regionListBox {
          display: block;
          div {
            cursor: pointer;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: hidden;
            &:hover {
              background-color: #eaeaea;
            }
          }
        }
      }
    }
    .cityInputBox {
      &.focus {
        .regionListBox {
          display: block;
          div {
            cursor: pointer;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: hidden;
            &:hover {
              background-color: #eaeaea;
            }
          }
        }
      }
    }
  }
}