.WorkerWorkTask {
  //overflow-x: hidden;
  height: 100%;
  .container {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px 30px;
    .head {
      //height: 90px;
      margin-top: 20px;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 20px;
      background: rgb(71,81,120);
      background: linear-gradient(0deg, rgba(71,81,120,0) 30%, rgba(71,81,120,1) 30%);
      padding: 15px 20px;
      border-radius: 16px 16px 0 0;
      color: white;
      .avatar {
        height: 75px;
        width: 75px;
        border-radius: 50%;
        background-color: #cfd4d9;
        border: 4px white solid;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        svg {
          width: 60%;
        }
      }
      .profileMain {
        overflow: hidden;
        margin-top: 10px;
        opacity: 1;
        align-self: start;
        &.fetch {
          opacity: 0;
        }
        .name, .place, .rating {
          height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .name {
          font-weight: bold;
        }
      }
      @media screen and (max-width: 500px){
        grid-template-columns: 1fr;
        background: linear-gradient(0deg, rgba(71,81,120,0) 0%, rgba(71,81,120,1) 0%);
        border-radius: 16px;
        padding: 15px 15px;
        .profileMain {
          margin-top: 0;
        }
      }
    }
    .main {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 30px;
      .mainInner {
        height: 100%;
        display: grid;
        grid-template-rows: auto auto 1fr;
        .taskInfo {
          margin-top: 10px;
          .DoMake {
            font-weight: 700;
            font-size: 24px;
            color: #212B36;
          }
          .description {
            margin-top: 10px;
          }
          .deadlineAndPrice {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 10px;
            border-bottom: dashed 1px gray;
            padding: 10px 0;
            .price {
              display: flex;
              gap: 10px;
              .priceTitle {
                font-weight: bold;
              }
            }
            .deadline {
              display: flex;
              gap: 10px;
              .deadlineTitle {
                font-weight: bold;
              }
            }
          }
        }
        .mainInfoInner {
          display: none;
        }
        @media screen and (max-width: 991px){
          order: 2;
          .mainInfoInner {
            display: block;
            margin: 20px auto 0;
            max-width: 620px;
          }
        }
      }
      .mainInfo {
        position: sticky;
        top: 120px;
        display: block;
        width: 300px;
      }

      @media screen and (max-width: 991px){
        grid-template-columns: 1fr;
        .mainInfo {
          display: none;
        }
      }
    }
    @media screen and (max-width: 500px){
      padding: 0 10px 20px;
    }
  }
}
.InfoInner {
  position: sticky;
  top: 120px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  .status {
    width: 100%;
    max-width: 300px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
    background: #F9F9F9;
    border: 1px solid #F0F0F0;
    box-shadow: -38px 38px 76px -7px rgba(145, 158, 171, 0.24);
    border-radius: 7px;
    padding: 15px;
    @media screen and (max-width: 650px){
      max-width: 472px;
    }
    @media screen and (max-width: 500px){
      max-width: 100%;
    }
    .statusTitle {
      font-weight: bold;
      font-size: 16px;
    }
    .statusValue {
      justify-self: end;
      width: 100%;
      max-width: 250px;
      min-height: 30px;
      height: 100%;
      .statusWorked {
        height: 100%;
        background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
      }
      .statusTest {
        height: 100%;
        background: linear-gradient(135deg, #FFA48D 0%, #FF4842 100%);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
      }
      .statusFinish {
        height: 100%;
        background: linear-gradient(135deg, #5BE584 0%, #007B55 100%);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
      }
      .statusArbitrage {
        height: 40px;
        background: linear-gradient(135deg, #B8C4CE 0%, #7C90A0 100%);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .btnBox {
    width: 100%;
    max-width: 300px;
    background: #F9F9F9;
    border: 1px solid #F0F0F0;
    box-shadow: -38px 38px 76px -7px rgba(145, 158, 171, 0.24);
    border-radius: 7px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media screen and (max-width: 650px){
      max-width: 472px;
    }
    @media screen and (max-width: 500px){
      max-width: 100%;
    }
    .testBtn, .cancelTestBtn,.disableBtn {
      min-height: 40px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(135deg, #d9d9d9 0%, #c5c5c5 100%);
      border-radius: 8px;
      color: gray;
      font-weight: bold;
      cursor: default;
      img {
        height: 30px;
      }
    }
    .testBtn {
      background: linear-gradient(135deg, #23da2e 0%, #13810a 100%);
      color: white;
      cursor: pointer;
    }
    .cancelTestBtn {
      background: linear-gradient(135deg, #ff8484 0%, #b71919 100%);
      color: white;
      cursor: pointer;
    }
  }
  .fileList {
    width: 100%;
    max-width: 300px;
    background: #F9F9F9;
    border: 1px solid #F0F0F0;
    box-shadow: -38px 38px 76px -7px rgba(145, 158, 171, 0.24);
    border-radius: 7px;
    overflow: hidden;
    @media screen and (max-width: 650px){
      max-width: 472px;
    }
    @media screen and (max-width: 500px){
      max-width: 100%;
    }
    .showFileListBtn {
      padding: 25px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-weight: bold;
      .fileListBtnIcon {
        transform: rotate(180deg);
        &.show {
          transform: rotate(0deg);
        }
      }
    }
    .fileBox {
      height: 0;
      overflow: hidden;
      padding: 0 25px;
      &.show {
        height: auto;
        padding-bottom: 25px;
      }
    }
  }
  .arbitrageBtn {
    width: 100%;
    max-width: 300px;
    background: #F9F9F9;
    border: 1px solid #F0F0F0;
    box-shadow: -38px 38px 76px -7px rgba(145, 158, 171, 0.24);
    border-radius: 7px;
    @media screen and (max-width: 650px){
      max-width: 472px;
    }
    @media screen and (max-width: 500px){
      max-width: 100%;
    }
    .showArbitrageBoxBtn {
      padding: 25px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-weight: bold;
      .arbitrageBtnIcon {
        transform: rotate(180deg);
        &.show {
          transform: rotate(0deg);
        }
      }
    }
    .arbitrageBox {
      height: 0;
      overflow: hidden;
      padding: 0 25px;
      &.show {
        height: auto;
        padding-bottom: 25px;
      }
      .prompt {
        font-weight: 300;
        font-size: 14px;
        line-height: 144%;
        color: #637381;
      }
      .showArbitrageBtn {
        margin-top: 15px;
        background: #DDE0E8;
        box-shadow: 0px 8px 16px rgba(196, 205, 213, 0.24);
        border-radius: 8px;
        height: 40px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          height: 70%;
        }
        &:hover {
          background: #cdd0d9;
        }
      }
    }
  }
}
.WorkerArbitrage {
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    position: relative;
    margin: 20px;
    max-width: 600px;
    width: 100%;
    max-height: calc(100vh - 40px);
    overflow-x: hidden;
    overflow-y: auto;
    background: #F9FAFB;
    box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16);
    border-radius: 8px;
    padding: 30px;
    .title {
      margin-top: 10px;
      .titleValue {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #212B36;
        text-align: center;
      }
      .subTitleValue {
        margin-top: 20px;
        font-weight: 300;
        font-size: 15px;
        line-height: 132.02%;
        text-align: center;
        color: #637381;
      }
    }
    .general {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-auto-rows: 1fr;
      gap: 20px;
      .generalItem {
        text-align: center;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DDE0E8;
        filter: drop-shadow(0px 8px 16px rgba(210, 210, 210, 0.24));
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background: #eeeeee;
        }
        &.active {
          background: #DDE0E8;
          font-weight: bold;
        }
      }
    }
    .percent {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 150px;
      gap: 20px;
      .percentTitle {
        font-weight: bold;
      }
      .percentBtn {
        position: relative;
        .percentValue {
          height: 34px;
          width: 100%;
          background: #EBF8F1;
          border: 1px solid #F0F0F0;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          cursor: pointer;
          svg {
            fill: #637381;
            transform: rotate(0deg);
            &.show {
              transform: rotate(-180deg);
            }
          }
        }
        .percentBox {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 38px;
          width: 100%;
          max-height: 200px;
          overflow-y: auto;
          background-color: white;
          border: rgba(99, 115, 129, 0.44) solid 1px;
          //border-radius: 8px;
          //height: 180px;
          //display: flex;
          //flex-direction: column;
          //overflow: hidden;
          span {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
              background-color: #eeeeee;
            }
          }
          &.show {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
    .comment {
      margin-top: 30px;
      height: 120px;
      textarea {
        width: 100%;
        height: 100%;
        resize: none;
        padding: 15px;
        border-radius: 8px;
      }
    }
    .btnBox {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      .send, .cancel {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E0E4E8;
        box-shadow: 0px 8px 16px rgba(196, 205, 213, 0.24);
        border-radius: 8px;
        cursor: pointer;
      }
      .send {
        background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
        box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
        color: white;
      }
    }
    .closeBtn {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      svg {
        fill: #4b4b4b;
      }
      &:hover {
        svg {
          fill: #2f2f2f;
        }
      }
    }
    @media screen and (max-width: 520px){
      .title {
        .titleValue {
          font-size: 24px;
          line-height: 28px;
        }
        .subTitleValue {
          font-weight: 300;
          font-size: 14px;
        }
      }
      .general {
        margin-top: 20px;
        grid-template-columns: 1fr;
      }
      .percent {
        grid-template-columns: 1fr;
        text-align: center;
        .percentBtn {
          margin: 0 auto;
          max-width: 150px;
          width: 100%;
          .percentBox {
            max-width: 150px;
          }
        }

      }
      .btnBox {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }
  }
}