.AddFiles {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  //height: 100px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 800px;
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #212B36;
  }
  .limit {
    margin-top: 15px;
    font-weight: 400;
    font-size: 14px;
    color: #637381;
  }

  .addedFileListMain {
    padding: 20px 0 0;
    min-height: 100px;
    display: flex;
    gap: 20px;
    //align-items: center;
    .addFileBtn {
      flex: 0 0 80px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--backgroundDefault);
      border-radius: 8px;
      cursor: pointer;
      color: var(--colorGreen);
      input {
        width: 0;
        height: 0;

      }
      &:hover {
        background: var(--backgroundGrayLite);
      }
    }
    .loadingFileList {
      margin-top: 15px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
    .fileList {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-wrap: wrap;
      .addedFileItem {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 82px;
        height: 100px;
        overflow: hidden;
        background-color: var(--backgroundGrayLite);
        border-radius: 5px;
        padding: 8px;
        cursor: pointer;
        gap: 5px;
        div {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          font-size: 10px;
        }
        .fileSizeText {
          font-weight: bold;
        }
        .fileImgBox {
          justify-self: center;
          height: 50px;
          width: 40px;
          display: flex;
          justify-content: center;
          background-color: white;
          padding: 3px;
        }
        .upload {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.37);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 60%;
            height: auto;
          }
        }
        .deleteFile {
          display: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.37);
        }
        &:hover {
          .deleteFile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            color: #d7d7d7;
          }
        }
      }
    }
  }
}