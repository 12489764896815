.ChatActiveTask {
  margin-top: 15px;
  min-height: 400px;
  //height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  border-top: dashed 1px transparent;


  @media screen and (max-width: 991px) {
    margin-top: 25px;
    padding-top: 10px;
    border-top: dashed 1px gray;
  }
}