.ActiveChat {
  //margin-top: 15px;
  //min-height: 500px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  //max-height: calc(100vh - 140px);
  .backChatList {
    position: sticky;
    z-index: 1;
    top: 84px;
    height: 0;
    overflow: hidden;
    display: none;
    background: #F9FAFB;
    margin-bottom: 15px;
    font-size: 16px;
    span {
      margin: 0 5px;
      padding: 0 1px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;
      color: #6e6e6e;
      border-bottom: transparent solid 2px;
      &:hover {
        color: #4d4d4d;
        cursor: pointer;
        font-weight: bold;
        border-bottom: #6e6e6e solid 2px;
      }
    }
    &.active {
      height: 40px;
      border-bottom: rgba(55, 53, 61, 0.25) solid 1px;
    }
  }
  .messageList {
    //min-height: 300px;
    min-height: 400px;
    max-height: 600px;
    height: 100%;
    overflow-y: scroll;
    padding: 0 10px;
    .addMessageListBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      img {
        height: 24px;
      }
    }
    @media screen and (max-width: 520px) {
      max-height: calc(100vh - 250px);
    }
  }
  .sendMessageBox {
    background: #F9FAFB;;
    //position: sticky;
    //bottom: 0;
    margin: 5px 0 0;
    .newMessage {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 15px;
      //&.contentEditable {
      //  grid-template-columns: 1fr;
      //  .addFileBtn {
      //    display: none;
      //  }
      //}
      .addFileBtn {
        position: relative;
        //top: 2px;
        width: 50px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          width: 0;
          height: 0;
          opacity: 0;
        }
        label {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        &:hover {
          border-radius: 8px;
          background: #cdd0d9;
          box-shadow: 0px 8px 16px rgba(196, 205, 213, 0.24);
          cursor: pointer;
        }
      }
      .newMessageInput {
        width: 100%;
        border-bottom: dashed 1px black;
        border-top: transparent 1px dashed;
        padding: 5px 0;
        line-height: 1.2;
        white-space: pre-wrap;
        word-wrap: anywhere;
        &.focus {
          border-top: dashed 1px black;
          overflow-y: hidden;
        }
        &.focus div:first-child{
          overflow-y: hidden;
        }
        &.focus div:first-child:focus{
          overflow-y: auto;
        }
        & div {
          &:first-child {
            max-height: 68px;
            overflow-x: hidden;
            //overflow-y: hidden;
          }
          &.placeholder {
            padding-left: 5px;
          }
        }
      }
      .textAreaBox {
        display: flex;
        align-items: flex-end;
        border-bottom: dashed 1px black;
        textarea {
          height: 31px;
          max-height: 84px;
          width: 100%;
          overflow-y: hidden;
          resize: none;
          line-height: 1.4;
          padding: 5px;
          font-size: 15px;
          &::-webkit-scrollbar-button {
            height: 0;
          }
        }
      }
    }
    .fileList {
      //border-bottom: dashed 1px black;
      display: flex;
      flex-wrap: wrap-reverse;
      gap: 3px;
      padding: 0 0 0 65px;
      .minFileItem {
        margin: 5px 5px 5px 0;
        position: relative;
        height: 22px;
        max-width: 150px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        background-color: rgba(0, 171, 23, 0.16);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;
        span {
          text-align: end;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .deleteFile {
          display: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.37);
          backdrop-filter: blur(1px);
        }
        &:hover {
          .deleteFile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #d7d7d7;
          }
        }
      }
    }
    .sendBtn {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 400px){
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 767px) {
    grid-template-rows: auto 1fr auto;
    .backChatList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
.Message {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.16);
  border-radius: 8px;
  padding: 20px;
  margin: 0 200px 20px 0;
  white-space: pre-wrap;
  &.myMessage {
    margin: 0 0 20px 200px;
  }
}
.ownerMessage {
  margin-left: 20%;
  background: #DDE0E8;
}
.companionMessage {
  margin-right: 20%;
  background: #FFFFFF;
}
.companionMessage, .ownerMessage {
  position: relative;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.16);
  padding: 15px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
  .avatar {
    align-self: start;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #cfd4d9;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      width: 60%;
    }
  }
  .main {
    overflow-x: hidden;
    .text {
      white-space: pre-wrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .fileList {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .fileItem {
        display: flex;
        align-items: center;
        padding: 2px 0;
        cursor: pointer;
        svg {
          position: relative;
          top: 1px;
          height: 14px;
        }
        span {
          color: #00ab55;
        }
        &:hover {
          svg path{
            fill: #01c762;
          }
          span {
            color: #01c762;
          }
        }
      }
    }
  }

  .createDate {
    position: absolute;
    bottom: 2px;
    right: 15px;
    font-size: 10px;
    color: #637381;
  }
}
.TaskMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50px;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 15px;
  flex-direction: column;
  .taskMessageDate {
    margin-top: 5px;
    color: gray;
  }
}
.ArbitrageMessage {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 171, 85, 0.39);
  align-items: center;
  text-align: center;
  min-height: 50px;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 15px;
  flex-direction: column;
  .arbitrageMessageDate {
    margin-top: 5px;
    color: gray;
  }
}