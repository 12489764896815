
.Respond {
  margin-top: 30px;

  &.disable {
    pointer-events: none;
  }
  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #212B36;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    align-items: center;
    img {
      height: 30px;
    }
    .deleteRespond {
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      color: rgba(168, 0, 0, 0.6);
      cursor: pointer;
      span:hover {
        color: #af0303;
        text-decoration: underline;
      }
    }
  }
  .answer {
    position: relative;
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 7px;
    //height: 150px;
    //padding: 10px 20px 10px;
    //padding-bottom: 30px;
    padding-right: 5px;
    min-height: 50px;
    display: grid;
    grid-template-columns: 1fr;
    //@media screen and (max-width: 520px){
    //  height: 200px;
    //}
    .maxLength {
      position: absolute;
      bottom: 8px;
      right: 20px;
      color: gray;
    }
  }
  .myAnswerRespond {
    .myAnswer {
      margin-top: 20px;
      min-height: 50px;
      background: #eeeeee;
      border-radius: 8px;
      padding: 15px;
      white-space: pre-wrap;

      .text {
        max-height: 185px;
        overflow-y: auto;
      }
    }
  }

  .offerData {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .offerDeadline {
      max-width: 310px;
      width: 100%;
      .offerDeadlineTitle {
        font-weight: bold;
      }
      .offerDeadlineMain {
        position: relative;
        margin-top: 10px;
        height: 50px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E9EEFB;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        cursor: pointer;
        &.disable {
          pointer-events: none;
          background: #eeeeee;
        }
        .icon {
          //width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg path {
            fill: #37353D;
          }
        }
        .pickerBox {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 55px;
          z-index: 1;
          width: 265px;
          &.show {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
    .offerPrice {
      max-width: 310px;
      width: 100%;
      .offerPriceTitle {
        font-weight: bold;
      }
      .offerPriceMain {
        margin-top: 10px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #E9EEFB;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        cursor: pointer;
        &.disable {
          pointer-events: none;
          background: #eeeeee;
        }
      }
    }
  }
  .myOfferData {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .offerDeadline {
      max-width: 310px;
      width: 100%;
      .offerDeadlineTitle {
        font-weight: bold;
      }
      .offerDeadlineMain {
        position: relative;
        margin-top: 10px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #E9EEFB;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        cursor: pointer;
        &.disable {
          pointer-events: none;
          background: #eeeeee;
        }
        .icon {
          //width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg path {
            fill: #37353D;
          }
        }
        .pickerBox {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 55px;
          z-index: 1;
          width: 265px;
          &.show {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
    .offerPrice {
      max-width: 310px;
      width: 100%;
      .offerPriceTitle {
        font-weight: bold;
      }
      .offerPriceMain {
        margin-top: 10px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #E9EEFB;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        cursor: pointer;
        &.disable {
          pointer-events: none;
          background: #eeeeee;
        }
      }
    }
  }
  .btnBox {
    margin: 30px 0 0;
    max-width: 640px;
    @media screen and (max-width: 677px){
      max-width: 310px;
      margin: 30px auto 0;
    }
  }
  .agreeBtn {
    margin: 30px 0;
    max-width: 640px;
    &.disable {
      opacity: 0.3;
    }
    @media screen and (max-width: 677px){
      max-width: 310px;
      margin: 30px auto 0;
    }
  }
  @media screen and (max-width: 1100px){
    margin: 30px auto 0;
    max-width: 640px;
    .offerData {
      justify-content: center;
    }
    .myOfferData {
      justify-content: center;
    }
  }

}