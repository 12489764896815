
.Billing {
  background-color: #f1f1f1;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
  }
}
.AvailableFunds {
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  .balance {
    display: flex;
    gap: 20px;
    align-items: center;
    .money {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .withdraw {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .title {
      width: 100%;
    }
    input {
      border: #637381 solid 1px;
      width: 250px;
      height: 34px;
      padding-left: 10px;
      font-size: 16px;
      border-radius: 5px;
    }
    button {
      min-width: 180px;
      border-radius: 5px;
      height: 34px;
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 24px;
      }
    }
  }
  .history {
    margin-top: 20px;

    .historyItem {
      margin-top: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .index {
        min-width: 40px;
      }
      .amount {
        font-size: 16px;
        font-weight: bold;
        margin-right: 20px;
      }
      .backWithdraw {
        width: 150px;
        height: 30px;
        background-color: #eeeeee;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          height: 24px;
        }
        &:hover {
          font-weight: bold;
        }
      }
    }
    .loadingList {
      img {
        margin: 5px 50px;
        height: 24px;
      }
    }
    .historyListEmpty {
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      color: gray;
    }
    .showMore {
      margin-top: 10px;
      //width: 100%;
      height: 50px;
      display: flex;
      //justify-content: center;
      align-items: center;
      grid-column: span 3;
      img {
        height: 24px;
      }
      span {
        height: 40px;
        max-width: 220px;
        width: 100%;
        border: #d5d5d5 solid 1px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
.SetPercent {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .title {
    width: 100%;
  }
  input {
    border: #637381 solid 1px;
    width: 50px;
    height: 34px;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 5px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    min-width: 180px;
    border-radius: 5px;
    height: 34px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 24px;
    }
  }
}