
.GuestLayout {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: scroll;
  @media screen and (max-width: 1200px) {
    max-width: calc(100vw);
    overflow-x: hidden;
  }
}