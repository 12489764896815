
.Content {
  background-color: #f1f1f1;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.Home {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: gray;
    text-align: center;
  }
  .text {
    margin-top: 20px;
    textarea {
      width: 100%;
      height: 100px;
      resize: none;
      font-size: 24px;
      box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.16);
      padding: 10px;
    }
  }
  button {
    display: block;
    margin: 20px auto 0;
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
    padding: 10px 20px;
    height: 40px;
    img {
      height: 22px;
    }
  }
}
.About {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  .aboutTitle {
    font-size: 18px;
    font-weight: bold;
    color: gray;
  }
  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    .subTitle {
      color: gray;
      font-size: 18px;
      width: 130px;
    }
    input {
      box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.16);
      height: 34px;
      padding-left: 10px;
      min-width: 250px;
      width: 100%;
    }
    textarea {
      width: 100%;
      resize: none;
      height: 80px;
      box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.16);
      padding: 10px;
    }
  }
  button {
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
    padding: 10px 20px;
    height: 40px;
    img {
      height: 22px;
    }
  }
}