
.VerifyBlock {
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .main {
      background: #FFFFFF;
      box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2);
      border-radius: 7px;
      padding: 30px;

      .FieldList {
        max-width: 900px;
        .prof {
          margin-top: 20px;
          display: flex;
          .profBtn {
            margin-left: 20px;
            cursor: pointer;
            font-weight: 400;
            font-size: 16px;
            display: flex;
            align-items: center;
            input {
              width: 26px;
              height: 26px;
              cursor: pointer;
            }
            span {
              margin-left: 15px;
            }
          }
        }
        .oneItemBox {
          margin-top: 20px;

        }
        .twoItemBox {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
          @media screen and (max-width: 767px) {
            grid-template-columns: 1fr;
          }
        }
        .field {
          position: relative;

          .fieldTitle {
            position: absolute;
            left: 20px;
            top: -8px;
            background-color: white;
            padding: 0 5px;
            color: var(--colorGreyLightText);
          }
          .fieldMain {
            min-height: 50px;
            width: 100%;
            overflow: hidden;
            border-radius: 8px;
            border: 1px solid rgba(145, 158, 171, 0.32);
            input {
              height: 50px;
              width: 100%;
              background-color: #ffffff;
              padding-left: 20px;
              font-size: 16px;
              color: var(--colorBlackText);
            }
            .comment {
              width: calc(100% - 30px);
              height: 100px;
              resize: none;
              min-height: 50px;
              margin: 15px 20px;
              font-size: 16px;
              color: var(--colorBlackText);
            }
          }
        }
      }
      .FileList {
        margin-top: 40px;
        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          color: var(--colorBlackText);
        }
        .subTitle {
          margin-top: 10px;
          font-size: 16px;
          line-height: 25px;
          color: var(--colorBlackText);
        }
        .loadingFileList {
          min-height: 100px;
          display: flex;
          align-items: center;
        }
        .addedFileListMain {
          padding: 20px 0;
          min-height: 100px;
          display: flex;
          gap: 20px;
          align-items: center;
          .addFileBtn {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--backgroundDefault);
            border-radius: 8px;
            cursor: pointer;
            color: var(--colorGreen);
            input {
              width: 0;
              height: 0;

            }
            &:hover {
              background: var(--backgroundGrayLite);
            }
          }
          .fileList {
            display: flex;
            gap: 20px;
            align-items: center;
            .addedFileItem {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 62px;
              height: 80px;
              overflow: hidden;
              background-color: var(--backgroundGrayLite);
              border-radius: 5px;
              padding: 8px;
              cursor: pointer;
              gap: 5px;
              div {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
                font-size: 8px;
              }
              .fileSizeText {
                font-weight: bold;
              }
              .fileImgBox {
                justify-self: center;
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                background-color: white;
                padding: 3px;
              }
              .upload {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.37);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 60%;
                  height: auto;
                }
              }
              .deleteFile {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.37);
              }
              &:hover {
                .deleteFile {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 26px;
                  color: #d7d7d7;
                }
              }
            }
          }
        }
      }
    }
  }
  &.disable {
    pointer-events: none;
  }
}