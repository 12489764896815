
.RegularLayout {
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  //width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #F9FAFB;

}