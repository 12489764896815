
.AboutPage {
  //min-height: 100vh;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    //height: 100%;
    .firstBlock {
      padding: 0 10px;
      position: relative;
      display: grid;
      grid-template-columns: 0.75fr 1fr;
      gap: 20px;
      min-height: 700px;
      .firstBlockInfo {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 34px;
        .firstBlockInfoTitle {
          font-weight: 700;
          font-size: 35px;
          line-height: 41px;
          color: #212B36;
        }
        .firstBlockInfoSubTitle {
          font-weight: 700;
          font-size: 20px;
          color: #212B36;
        }
        .firstBlockInfoText {
          padding-right: 10%;
          font-weight: 300;
          font-size: 18px;
          line-height: 159.5%;
          color: #212B36;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
      .firstBlockImg {
        z-index: 1;
        display: flex;
        align-items: flex-end;
        .imgBox {
          position: relative;
          img {
            display: block;
            margin: 0;
            width: 120%;
            //max-width: 600px;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        margin-top: 30px;
        grid-template-columns: 1fr;
        .firstBlockInfo {
          padding: 0 10%;
        }
        .firstBlockImg {
          margin-top: 30px;
          .imgBox {
            margin: 0 auto;
            max-width: calc(100vw - 20px);
            overflow: hidden;
            display: flex;
            justify-content: center;
            img {
              min-width: 400px;
              width: 100%;
              object-position: center center;
              //max-width: 600px;
            }
          }
        }
      }
      @media screen and (max-width: 767px) {
        //margin-top: 30px;
        //grid-template-columns: 1fr;
        .firstBlockInfo {
          padding: 0 6%;
          .firstBlockInfoTitle {
            font-size: 30px;
            line-height: 34px;
          }
          .firstBlockInfoSubTitle {
            font-size: 18px;
          }
          .firstBlockInfoText {
            font-size: 16px;
          }
        }
      }
      @media screen and (max-width: 600px) {
        margin-top: 30px;
        grid-template-columns: 1fr;
        .firstBlockInfo {
          padding: 0 2%;
          text-align: center;
          .firstBlockInfoTitle {

          }
          .firstBlockInfoSubTitle {

          }
          .firstBlockInfoText {

          }
        }
      }
    }
    .twoBlock {
      margin-top: 70px;
      .twoBlockTitle {
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 35px;
        line-height: 41px;
        color: #212B36;
      }
      .twoBlockMain {
        margin-top: 70px;
        padding: 0 10px;
        position: relative;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 20px;
        .twoBlockMainImg {
          display: flex;
          align-items: flex-end;
          .imgBox {
            img {
              width: 100%;
            }
          }
        }
        .twoBlockMainInfo {
          align-self: center;
          max-width: 400px;
          margin: 20px;
          background: #FFFFFF;
          box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
          padding: 40px;
          .twoBlockMainInfoTitle {
            display: none;
          }
          .mainInfo {
            min-height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            min-width: 270px;
            .executorCount {
              .count {
                font-weight: 700;
                font-size: 50px;
                line-height: 59px;
                color: #637381;
                word-wrap: anywhere;

              }
              .subTitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #212B36;
                word-wrap: anywhere;
              }
            }
            .load {
              margin: auto;
              height: 30px;
              width: 30px;
            }
          }
          @media screen and (max-width: 375px) {
            padding: 15px;
          }
        }
      }
      @media screen and (max-width: 991px) {
        margin-top: 0;
        .twoBlockTitle {
          display: none;
        }
        .twoBlockMain {
          z-index: 2;
          margin-bottom: -100px;
          position: relative;
          top: -100px;
          margin-top: 0;
          grid-template-columns: 1fr;
          justify-content: center;
          .twoBlockMainImg {
            order: 2;
          }
          .twoBlockMainInfo {
            margin: 0 auto;
            order: 1;
            .twoBlockMainInfoTitle {
              display: block;
              font-size: 22px;
              font-weight: bold;
              text-align: center;
            }
            .mainInfo {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}