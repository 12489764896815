
.GuestHeader {
  position: sticky;
  z-index: 3;
  top: 0;
  height: 105px;
  background: white;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  .container {
    position: relative;
    z-index: 2;
    height: 100%;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    .main {
      height: 100%;
      display: grid;
      grid-template-columns: auto 1fr auto auto;
      align-items: center;
      justify-content: space-between;
      grid-column-gap: 20px;
      .burgerBtn {
        position: relative;
        display: none;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .showBtn, .hideBtn {
          position: absolute;
          //width: 80%;
          visibility: hidden;
          opacity: 0;
          &.active {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.1s linear 0s;
          }
        }
        .hideBtn {
          width: 80%;
          height: 80%;
        }
      }
      .logo {
        text-decoration: none;
        padding: 0 10px;
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #212B36;
        }
      }
      .navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        padding: 0 24px;
        a {
          text-decoration: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          color: #212B36;
        }
      }
      .searchBtn {
        padding: 0 6px;
        svg {
          width: 21px;
          height: 21px;
          fill: #212B36;
        }
      }
      .auth {
        padding: 0 10px 0 0;
        display: flex;
        align-items: center;
        gap: 20px;
        .registrationBtn {
          font-weight: 400;
          font-size: 15px;
          line-height: 26px;
          color: #212B36;
          cursor: pointer;
        }
        .loginBtn {
          background: #D9D9D9;
          border-radius: 95px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 20px;
          line-height: 19px;
          cursor: pointer;
        }
      }
    }

  }
  .mobileMain {
    display: none;
    position: absolute;
    z-index: 1;
    top: calc(-350px);
    background: #FFFFFF;
    border: 1px dashed #D8D8D8;
    width: 100%;
    padding: 20px 0;
    transition: top 0.3s;
    opacity: 0;
    &.show {
      top: 85px;
      opacity: 1;
      transition: opacity 0.3s linear 0.1s;
    }
    .navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      a {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: #212B36;
      }
    }
  }
  @media screen and (max-width: 991px) {
    height: 85px;

    .container {
      .main {
        grid-template-columns: auto 1fr auto auto;
        grid-column-gap: 10px;
        .burgerBtn {
          display: flex;
        }
        .navigation {
          display: none;
        }
      }
    }
    .mobileMain {
      display: block;
    }
  }
  @media screen and (max-width: 520px) {
    height: 85px;
    .container {
      .main {
        grid-template-columns: auto 1fr auto;
        .searchBtn {
          display: none;
        }
        .auth {
          .registrationBtn {
            display: none;
          }
          .loginBtn {
            background: #D9D9D9;
            border-radius: 95px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 20px;
            line-height: 19px;
            cursor: pointer;
          }
        }
      }
    }
  }
}