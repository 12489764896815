
.ContactsPage {
  background: #F9FAFB;
  padding: 0 10px;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 80px;
    .leftBlock {

      .title {
        font-size: 28px;
        font-weight: bold;
      }
      .address {
        max-width: 500px;
        margin-top: 20px;
        display: flex;
        gap: 10px;
        .subTitle {
          font-weight: bold;
        }
      }
      .map {
        margin-top: 20px;
      }
      .contacts {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .phone {
          max-width: 220px;
          width: 100%;
          display: flex;
          gap: 5px;
          .phoneTitle {
            font-weight: bold;
          }
          .phoneText {
            a {
              color: black;
            }
          }
        }
        .email {
          display: flex;
          gap: 5px;
          .emailTitle {
            font-weight: bold;
          }
          .emailText {
            a {
              color: black;
            }
          }
        }
      }
      .companyData {
        margin-top: 40px;
        .data {
          display: flex;
          flex-wrap: wrap;
          max-width: 400px;
          gap: 20px;
          .inn, .ogrn, .kpp, .okpo {
            display: flex;
            gap: 5px;
            min-width: 140px;
            .dataTitle {
              font-weight: bold;
            }
          }
        }
        .bank {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .check, .bankName, .bik, .corpCheck {
            display: flex;
            gap: 20px;
            .bankTitle {
              font-weight: bold;
              min-width: 140px;
            }
            .bankValue {
            }
          }
        }
        @media screen and (max-width: 375px){
          font-size: 12px;
          .data {
            gap: 10px;
          }
          .bank {
            gap: 10px;
            .check, .bankName, .bik, .corpCheck {
              gap: 10px;
              .bankTitle {
                font-weight: bold;
                min-width: 140px;
              }
              .bankValue {
              }
            }
          }
        }
      }
    }
    .rightBlock {
      .firstBlock {
        position: relative;
        display: grid;
        min-height: 700px;
        align-items: center;
        margin-top: 40px;
        grid-template-columns: 1fr;
        gap: 40px;
        .firstBlockMain {
          margin: 0 auto;
          max-width: 600px;
          .firstBlockMainTitle {
            font-size: 30px;
            line-height: 34px;
          }
          .firstBlockMainSubTitle {
            font-size: 16px;
            line-height: 120%;
          }
        }
        .firstBlockMain {
          //min-width: 430px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .firstBlockMainTitle {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #212B36;
          }
          .firstBlockMainSubTitle {
            font-weight: 300;
            font-size: 18px;
            line-height: 159.5%;
            color: #212B36;
          }
          .firstBlockMainForm {
            width: 85%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            input {
              height: 50px;
              display: flex;
              align-items: center;
              padding-left: 15px;
              background: #FFFFFF;
              border: 1.0452px solid #E9EEFB;
              border-radius: 8.36158px;
            }
            textarea {
              height: 120px;
              resize: none;
              padding: 15px;
              background: #FFFFFF;
              border: 1.0452px solid #E9EEFB;
              border-radius: 8.36158px;
              &::-webkit-scrollbar-track {
                border-radius: 5px;
                width: 8px;
                background-color: transparent;
                -webkit-box-shadow: none;
              }
              &::-webkit-scrollbar {
                border-radius: 8px;
                width: 5px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                //width: 10px;
                background-color: rgba(99, 115, 129, 0.42);
              }
            }
            button {
              background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
              box-shadow: 0px 4px 4px rgba(111, 147, 241, 0.24);
              border-radius: 8.6441px;
              color: white;
              height: 50px;
              img {
                height: 60%;
              }
            }
            @media screen and (max-width: 991px) {
              width: 100%;
            }
          }
        }
        .firstBlockImg {
          .imgBox {
            position: relative;
            img {
              display: block;
              margin: 0;
              width: 100%;
              //max-width: 600px;
            }
          }
        }
        @media screen and (max-width: 900px) {

        }
        @media screen and (max-width: 900px) {
          .firstBlockMain {
            .firstBlockMainTitle {
              text-align: center;
            }
            .firstBlockMainSubTitle {
              text-align: center;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1200px){
      gap: 40px;
    }
    @media screen and (max-width: 991px){
      gap: 20px;
      grid-template-columns: 1fr;
    }
  }
}