.VerifyBtn {
  grid-area: verifyBtnBox;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: var(--backgroundGradientBlue);
  border-radius: 8px;
  cursor: pointer;
  max-width: 400px;
  justify-self: center;
  width: 100%;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
  }
}