
html {
  --colorBlackText: #212B36;
  --colorGreyText: #637381;
  --colorGreyLightText: #919EAB;
  --colorWhiteLightText: #CCCFD9;
  --colorGreen: #00AB55;
  --colorRed: #ab0000;

  --backgroundOpacity: rgba(2, 2, 2, 0.57);
  --backgroundDefault: #F9FAFB;
  --backgroundGrayLite: #f1f1f1;
  --backgroundGrayBlue: #475178;;
  --backgroundGreen: #00AB55;

  --backgroundGradientBlue: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
}
