.History {
  align-self: start;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  overflow: hidden;
  @media screen and (max-width: 991px) {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  .header {
    background-color: #475178;
    padding: 15px 20px;
    display: flex;
    gap: 10px;
    //grid-template-columns: 1fr auto auto;
    color: white;
    .type {
      min-width: 90px;
      width: 100%;
    }
    .count {
      justify-self: center;
      min-width: 70px;
      max-width: 150px;
      width: 100%;
      text-align: center;
    }
    .status {
      justify-self: center;
      min-width: 70px;
      max-width: 150px;
      width: 100%;
      text-align: center;
    }
    .date {
      justify-self: center;
      min-width: 80px;
      max-width: 120px;
      width: 100%;
      text-align: center;
    }
  }
  .list {
    padding: 10px 0;
    .emptyList {
      padding: 10px 20px;
    }
    .showMore {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 24px;
      }
      span {
        height: 40px;
        max-width: 220px;
        width: 100%;
        border: #d5d5d5 solid 1px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.BillHistoryItem {
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  //grid-template-columns: 1fr auto auto;
  .type {
    width: 100%;
    min-width: 90px;
  }
  .count {
    justify-self: center;
    min-width: 70px;
    max-width: 150px;
    width: 100%;
    text-align: center;
  }
  .status {
    justify-self: center;
    min-width: 70px;
    max-width: 150px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    .success {
      color: #009400;
    }
    .pending {
      color: gray;
    }
    .cancel {
      color: #c90101;
    }
  }
  .date {
    justify-self: center;
    min-width: 80px;
    max-width: 120px;
    width: 100%;
    text-align: center;
  }
}
