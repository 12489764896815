
.User {
  margin-bottom: 15px;
  padding: 10px 20px;
  min-height: 50px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    .email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    @media screen and (max-width: 550px) {
      flex-wrap: wrap;
    }
  }
  .control {
    display: flex;
    align-items: center;
    gap: 20px;
    color: white;
    @media screen and (max-width: 550px) {
      justify-content: center;
    }
    .managerBtn {
      height: 30px;
      width: 190px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      &.disable {
        background-color: gray;
      }
      &.access {
        background-color: green;
      }
      &.stop {
        background-color: #6e0000;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}