.MyBill {
  background: #F9FAFB;
  .container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px 10px;
    display: grid;
    grid-template-columns: 350px 1fr;
    gap: 20px;
    .barMyBill {
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;
      gap: 20px;

      .balance {
        width: 100%;
        background: #F9F9F9;
        box-shadow: -38.4878px 38.4878px 76.9756px -7.69756px rgba(145, 158, 171, 0.24);
        border-radius: 7.69756px;
        padding: 30px;
        font-size: 18px;
        font-weight: bold;
        overflow: hidden;
        span {
          position: relative;
          top: 1px;
          margin: 0 5px 0 10px;
          font-size: 20px;
        }
        img {
          height: 24px;
        }
      }
      .navigateMyBill {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background: #F9F9F9;
        box-shadow: -38.4878px 38.4878px 76.9756px -7.69756px rgba(145, 158, 171, 0.24);
        border-radius: 7.69756px;
        padding: 30px 30px;
        .navBtn {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 18px;
          color: #212B36;
          &.active {
            svg path{
              fill: #00AB55;
            }
            span {
              color: #00AB55;
              font-weight: bold;
            }
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      .barMyBill {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}
