
.ResetPassword {
  position: relative;
  max-width: 640px;
  width: 100%;
  padding: 20px;
  background: var(--backgroundDefault);
  box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16);
  border-radius: 8px;
  .title {
    margin-top: 30px;
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: var(--colorBlackText);
  }
  .errorField {
    min-height: 20px;
    margin-top: 15px;
    color: var(--colorRed);
    text-align: center;
  }
  .form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .field {
      height: 50px;
      background: #FFFFFF;
      border: 1px solid #E9EEFB;
      border-radius: 8px;
      max-width: 400px;
      width: 100%;
      overflow: hidden;
      input {
        padding-left: 15px;
        width: 100%;
        height: 100%;
        font-size: 16px;
      }
      &.error {
        border: 1px solid var(--colorRed);
      }
    }
    button {
      margin-top: 20px;
      max-width: 400px;
      width: 100%;
      height: 60px;
      background: var(--backgroundGradientBlue);
      box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
      color: white;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      img {
        display: block;
        height: 40px;
        width: 40px;
      }
    }
  }
  .changeMode {
    max-width: 400px;
    width: 100%;
    margin: 20px auto 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .modeItem {
      font-weight: 700;
      font-size: 14px;
      line-height: 149.02%;
      text-decoration-line: underline;
      color: var(--colorGreen);
      cursor: pointer;
    }
  }
  @media screen and (max-width: 520px){
    padding: 10px;
    .title {
      font-size: 26px;
    }
    .changeMode {
      margin: 20px auto 30px;
      flex-direction: column;
      gap: 20px;
      .modeItem {
        //width: 100%;
        text-align: center;
      }
    }
  }
}