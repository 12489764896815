
.TextInputField {

  .main {
    margin-top: 10px;
    width: 100%;
    line-height: 1.4;
    white-space: pre-wrap;
    word-wrap: anywhere;
    overflow-x: hidden;
    .text {

    }

    input {
      border-bottom: dashed 1px black;
      width: 100%;
      padding: 5px 0;
    }
  }
  .maxLength {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }
}