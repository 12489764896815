
.DatePicker {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 310px;
  padding: 15px;
  min-height: 200px;
  background: #F9FAFB;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 8px;
  .head {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    height: 40px;
    align-items: center;
    .activeMonthAndYear {
      display: flex;
      gap: 10px;
      .month {
        font-weight: 600;
        font-size: 16px;
        color: #212B36;
      }
      .year {
        font-weight: 600;
        font-size: 16px;
        color: #212B36;
      }
    }
    .changeMonth {
      display: flex;
      .prev,.next {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 28px;
        color: #637381;
        font-weight: bold;
        padding-bottom: 5px;
        &:hover {
          color: black;
          transform: scale(1.3);
        }
        &::selection {
          background-color: transparent;
        }
      }
    }
  }
  .nameDaysBox {
    display: grid;
    grid-template-columns: repeat(7, 40px);
    grid-template-rows: 34px;
    .nameDay {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #919EAB;
    }
  }
  .calendar {
    display: grid;
    grid-template-columns: repeat(7, 40px);
    grid-template-rows: 34px;
    grid-auto-rows: 34px;
  }
}
.PrevDay,.CurrentDay,.NextDay {
  margin: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  &:hover {
    background: rgba(231, 231, 231, 0.16);
    border: 1px solid rgba(145, 158, 171, 0.24);
  }
}
.PrevDay {
  color: #919EAB;
}
.CurrentDay {
  display: flex;
  justify-content: center;
  align-items: center;
  &.current {
    background: rgba(145, 158, 171, 0.16);
    border: 1px solid rgba(145, 158, 171, 0.24);
  }
  &.active {
    background: #00AB55;
    border: 1px solid rgba(145, 158, 171, 0.24);
    color: white;
  }
}
.NextDay {
  color: #919EAB;
}