.SettingMainEmailNotice {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 7px;
  padding: 20px;
  display: flex;
  gap: 40px;
  .emailNoticeTitle {
    min-width: 200px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .emailNoticeMain {
    font-size: 18px;
    .alerts {
      line-height: 21px;
      display: flex;
      align-items: center;

      input {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      label {
        padding-left: 10px;
        cursor: pointer;
      }
    }
    .news {
      margin-top: 20px;
      line-height: 21px;
      display: flex;
      align-items: center;
      cursor: pointer;
      input {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      label {
        padding-left: 10px;
        cursor: pointer;
      }
    }
    .saveBtn {
      margin-top: 40px;
      grid-area: verifyBtnBox;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: var(--backgroundGradientBlue);
      border-radius: 8px;
      cursor: pointer;
      max-width: 250px;
      justify-self: center;
      width: 100%;
      img {
        height: 60%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .emailNoticeTitle {
      text-align: center;
    }
    .emailNoticeMain {
      .saveBtn {

        margin: 40px auto 0;
      }
    }
  }
}