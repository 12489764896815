
.RegularHeader {
  position: sticky;
  z-index: 10;
  top: 0;
  height: 105px;
  background: white;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;

  .container {
    height: 100%;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .main {
      height: 100%;
      display: grid;
      grid-template-columns: auto 1fr auto auto auto;
      align-items: center;
      grid-column-gap: 20px;
      .burgerBtn {
        position: relative;
        display: none;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .showBtn, .hideBtn {
          position: absolute;
          //width: 80%;
          visibility: hidden;
          opacity: 0;
          &.active {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.1s linear 0s;
          }
        }
        .hideBtn {
          width: 80%;
          height: 80%;
        }
      }
      .logo {
        text-decoration: none;
        //padding: 0 10px;
        margin-left: 10px;
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #212B36;
        }
      }
      .navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        padding: 0 24px;
        a {
          text-decoration: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          color: #212B36;
        }
        .basket {
          background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
          box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
          border-radius: 8px;
          padding: 10px 17px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            padding-left: 8px;
            color: #FFFFFF;
          }
        }
      }
      .messageNav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        a {
          font-weight: 400;
          font-size: 15px;
          line-height: 26px;
          color: #212B36;
        }
        .newMessage {
          height: 44px;
          background: #FF8D00;
          box-shadow: 0px 7.54286px 15.0857px rgba(240, 205, 100, 0.24);
          border-radius: 8px;
          color: white;
          padding: 0 12px;
          display: flex;
          align-items: center;
          gap: 5px;
          .count {
            font-weight: bold;
          }
        }
      }
      .searchBtn {
        padding: 5px;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .hintBtn {
        position: relative;
        .btn {
          height: 44px;
          background: #FF8D00;
          box-shadow: 0px 7.54286px 15.0857px rgba(240, 205, 100, 0.24);
          border-radius: 8px;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          min-width: 60px;
          span {
            font-weight: 700;
            font-size: 14.1429px;
            line-height: 25px;
            color: #FFFFFF;
          }
          &.empty {
            background-color: #d2d2d2;
            box-shadow: 0px 7.54286px 15.0857px rgba(234, 234, 234, 0.24);
          }
        }
        .popup {
          position: absolute;
          right: 0;
          top: 50px;
          background: #F9FAFB;
          box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16);
          border: solid 1px #eeeeee;
          border-radius: 8px;
          padding: 25px 10px 25px 25px;
          width: 300px;
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
          &.show {
            transition: opacity 0.3s;
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
          }
          .closeAllNoticeBtn {
            margin-right: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: var(--colorGreyText);
            transition: all 0.2s linear 0s;
            &:hover {
              //color: var(--colorGreyText);
              color: black;
              font-weight: 700;
            }
            .btn {
             margin-right: 20px;
            }
          }
          .noticeList {
            margin-top: 20px;
            padding-right: 15px;
            min-height: 100px;
            max-height: 300px;
            overflow-y: auto;
            .emptyNotice {
              margin-top: 25px;
              text-align: center;
            }
            .noticeItem {
              margin-bottom: 15px;
              padding: 5px 0;
              border-bottom: black dotted 1px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .date {
                background: #EBF8F1;
                border-radius: 6.39692px;
                padding: 2px 15px;
              }
              .text {
                margin-top: 10px;
                line-height: 16px;
                width: 100%;
              }
              .linkText {
                cursor: pointer;
                margin-top: 5px;
                width: 100%;
                font-weight: bold;
                color: black;
                text-decoration: underline;
              }
            }
          }
        }
      }
      .profileBtn {
        position: relative;
        margin-right: 10px;
        .btn {
          cursor: pointer;
          margin-right: 10px;
        }
        .popup {
          position: absolute;
          right: 0;
          top: 42px;
          background: #F9FAFB;
          box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16);
          border: solid 1px #eeeeee;
          border-radius: 8px;
          padding: 25px;
          width: 300px;
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
          &.show {
            transition: opacity 0.3s;
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
          }
          .profileInfo {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;
            .avatar {
              width: 55px;
              height: 55px;
              border-radius: 50%;
              background-color: #cfd4d9;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              svg {
                width: 60%;
              }
            }
            .profile {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .name {
                display: flex;
                align-items: center;
                height: 30px;
                white-space: nowrap;
                font-weight: 700;
              }
              .email {
                height: 30px;
                white-space: nowrap;
              }
              .addTaskBtn {
                margin-top: 0;
                display: none;
                .basket {
                  background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
                  box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
                  border-radius: 8px;
                  padding: 10px 17px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  span {
                    width: 100%;
                    text-align: center;
                    padding-left: 8px;
                    color: #FFFFFF;
                  }
                }
              }
              .messengerBtn,
              .myBillBtn,
              .myOrderBtn {
                display: none;
                color: var(--colorGreyText);
              }
              .showPopupChangePassword {
                margin: 0 0 15px;
                cursor: pointer;
                color: var(--colorGreyText);
              }
              a {
                display: block;
                margin: 0 0 15px;
                color: var(--colorGreyText);
              }
              .logout {
                cursor: pointer;
                color: var(--colorGreyText);
              }
            }
          }
        }
      }
    }
  }
  .mobileMain {
    display: none;
    position: absolute;
    z-index: 1;
    top: calc(-350px);
    background: #FFFFFF;
    border: 1px dashed #D8D8D8;
    width: 100%;
    padding: 20px 0;
    opacity: 0;
    &.show {
      top: 85px;
      opacity: 1;
      transition: opacity 0.3s linear 0s;
    }
    .navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .addTaskBtn {
        .basket {
          background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
          box-shadow: 0px 8px 16px rgba(111, 147, 241, 0.24);
          border-radius: 8px;
          padding: 10px 17px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            width: 100%;
            text-align: center;
            padding-left: 8px;
            color: #FFFFFF;
          }
        }
      }
      a {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        color: #212B36;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .container {
      .main {
        grid-template-columns: auto 1fr auto auto auto auto;

        .navigation {
          //display: none;
        }
        .messageNav {
          a {
            display: none;
            &:last-child {
              display: inline-block;
            }
          }
        }
        .profileBtn {
          .popup {
            .profileInfo {
              .profile {
                .myBillBtn,
                .myOrderBtn {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    height: 85px;
    .container {
      .main {
        grid-template-columns: auto 1fr auto auto;

        .navigation {
          padding: 0;
        }
        .searchBtn {
          display: none;
        }
        .messageNav {
          display: none;
        }
        .profileBtn {
          .popup {
            .profileInfo {
              .profile {
                .messengerBtn {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      .main {
        grid-template-columns: auto 1fr auto auto;

        .burgerBtn {
          display: flex;
        }
        .navigation {
          display: none;
        }
        .hintBtn {
          position: relative;
          .popup {
            position: fixed;
            right: 60px;
            //left: 50%;
            //transform: translateX(-50%);
            top: 70px;
            background: #F9FAFB;
            box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16);
            border: solid 1px #eeeeee;
            border-radius: 8px;
            padding: 25px;
            min-width: 300px;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            &.show {
              transition: opacity 0.3s;
              pointer-events: auto;
              opacity: 1;
              visibility: visible;
            }
            .closeAllNoticeBtn {
              cursor: pointer;
            }
            .noticeList {
              min-height: 300px;
              max-height: 300px;
              overflow-y: auto;
            }
          }
        }
        .profileBtn {
          position: relative;
          .popup {
            position: fixed;
            right: 20px;
            top: 70px;
            .profileInfo {
              .profile {
                .addTaskBtn {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .mobileMain {
      display: block;
    }
  }
  @media screen and (max-width: 420px) {
    .container {
      .main {

        .hintBtn {
          position: relative;
          .popup {
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            top: 70px;
          }
        }
        .profileBtn {
          position: relative;
          .popup {
            position: fixed;
            right: 20px;
            top: 70px;
          }
        }
      }
    }
  }
}