
.ExecutorList {
  background: #F9FAFB;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  .titlePage {
    margin-top: 45px;
    font-weight: 700;
    font-size: 45px;
    line-height: 53px;
    color: #212B36;
    @media screen and (max-width: 900px) {
      font-size: 34px;
      line-height: 43px;
    }
  }
  .search {
    margin-top: 30px;
    background: #EEF0F4;
    border-radius: 8px;
    padding: 30px;
    .inputBox {
      position: relative;
      z-index: 1;
      height: 46px;
      border-radius: 8px;
      overflow: hidden;
      input {
        height: 100%;
        width: 100%;
        background: #FFFFFF;
        padding-left: 15px;
      }
      .searchBtn {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        padding: 7px 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #EEF0F4;
        }
      }
    }
    @media screen and (max-width: 500px) {
      padding: 20px;
    }
  }
  .main {
    padding: 25px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    .filterBlock {
      align-self: start;
      position: sticky;
      top: 120px;
      //min-width: 270px;
      width: 100%;
      max-width: 280px;
      background: #F6F7F9;
      border: 0.962195px solid #F0F0F0;
      box-shadow: -38.4878px 38.4878px 76.9756px -7.69756px rgba(145, 158, 171, 0.24);
      border-radius: 7.69756px;
      padding: 20px;
      height: 80px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      &.show {
        height: 550px;
        cursor: default;
        transition: all 0.3s;
        .title {
          svg {
            opacity: 0;
          }
        }
        .filterMain {
          opacity: 1;
          transition: all 0.3s;
        }
      }
      .title {
        margin: 10px 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        span {
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          text-transform: uppercase;
          color: #212B36;
        }
        svg {
          transform: rotate(180deg);
          height: 20px;
          fill: black;
          transition: all 0.3s;
        }
      }
      .filterMain {
        opacity: 0;
        display: grid;
        grid-template-columns: 1fr;
        .getPlaceBtn {
          margin: 25px 10px 0;
          padding-bottom: 25px;
          .placeTitle {
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #212B36;
          }
          .placeBtnBox {
            margin-top: 15px;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 5px;
            .icon {
              margin-top: 5px;
            }
            .text {
              font-weight: 700;
              font-size: 16px;
              line-height: 25px;
              color: #00AB55;
              span{
                cursor: pointer;
                border-bottom: 1px #00AB55 dashed;
              }
            }
          }
        }
        .inputPlaceBox {
          max-width: 300px;
          margin: 25px 0 0;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .inputPlaceTitle {
            margin: 0 10px 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #212B36;
          }
        
        }
      }
      .sendBtn {
        max-width: 300px;
        margin: 25px auto 0;
        background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
        box-shadow: 0px 8px 15px rgba(111, 147, 241, 0.24);
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;
      }
      .clearFilterBtnBox {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        .clearFilterBtn {
          display: flex;
          align-items: center;
          svg {
            width: 8px;
            height: 8px;
          }
          .clearFilterText {
            padding-left: 5px;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #212B36;
          }
        }
      }
      .hiddenFilterBtnBox {
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        .clearFilterBtn {
          display: flex;
          align-items: center;
          svg {
            width: 10px;
            height: 10px;
            fill: #8A93A9;
          }
          .clearFilterText {
            padding-left: 5px;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #8A93A9;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        min-width: 280px;
      }
      @media screen and (max-width: 991px) {
        position: relative;
        top: 0;
        max-width: none;
        .title {
          text-align: center;
          justify-content: center;
        }
        .filterMain {
          margin-top: 20px;
          grid-template-columns: 1fr 1fr;
        }
        &.show {
          height: 440px;
        }
      }
      @media screen and (max-width: 600px) {
        position: relative;
        top: 0;
        width: 100%;
        min-width: auto;
        .filterMain {
          grid-template-columns: 1fr;
        }
        &.show {
          height: 550px;
        }
      }
    }
    .executorList {
      align-self: start;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      .showMore {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: span 3;
        img {
          height: 24px;
        }
        span {
          height: 40px;
          max-width: 220px;
          width: 100%;
          border: #d5d5d5 solid 1px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 991px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 660px) {
        grid-template-columns: 1fr;
      }
      @media screen and (max-width: 375px) {
        width: 100%;
      }
    }
    .emptyList {
      width: 100%;
      margin-top: 20px;
      font-weight: 700;
      color: #37353D;
      font-size: 22px;
      text-align: center;
    }
    .loadingList {
      width: 100%;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1200px) {
        max-width: 620px;
      }
      @media screen and (max-width: 991px) {
        max-width: none;
      }

      img {
        margin-top: 50px;
        width: 50px;
        height: 50px;
      }
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
}