.NoticeItem {
  position: relative;
  margin-bottom: 15px;
  padding: 5px 0;
  border-bottom: black dotted 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;
  .date {
    background: #EBF8F1;
    border-radius: 6.39692px;
    padding: 2px 15px;
  }
  .text {
    margin-top: 10px;
    line-height: 16px;
    width: 100%;
  }
  .linkText {
    cursor: pointer;
    margin-top: 5px;
    width: 100%;
    font-weight: bold;
    color: black;
    text-decoration: underline;
  }
  .closeBtn {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    svg {
      fill: #637381;
      height: 14px;
      width: 14px;
    }
    &:hover {
      background-color: #c0c0c0;
      color: white;
      cursor: pointer;
      svg {
        fill: white;
      }
    }
  }
  &:hover {
    .closeBtn {
      opacity: 1;
      pointer-events: auto;
    }
  }
}