
.OfferList {
  max-width: 800px;
  .offerTitle {
    margin: 30px 0;
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #212B36;
    }
    img {
      margin-left: 20px;
      height: 34px;
    }
  }
  .emptyList {
    margin: 20px auto;
    display: flex;
    font-size: 16px;
    font-weight: bold;
  }
}