
.Appeal {
  background-color: #f6f6f6;
  .container {
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    .title {
      margin-top: 20px;
      font-size: 22px;
      font-weight: bold;
    }
    .main {
      margin-top: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 20px;
      .loadingList {
        margin-top: 50px;
        height: 50px;
      }
      .showMore {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: span 3;
        img {
          height: 24px;
        }
        span {
          height: 40px;
          max-width: 220px;
          width: 100%;
          border: #d5d5d5 solid 1px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
}
.AppealItem {
  min-height: 50px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 2px 2px rgba(154, 154, 154, 0.28);
  border-radius: 8px;
  padding: 15px;
  .headItem {
    display: grid;
    grid-template-columns: 1fr auto;
    .topic {
      overflow-x: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
    .showBtn {
      width: 50px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      font-size: 34px;
      color: #58565d;
      &:hover {
        span {
          transform: scale(1.4);
        }
      }
    }
  }
  .mainItem {

    .description {
      word-wrap: anywhere;
    }
    .email {
      margin-top: 10px;
      color: #637381;
    }
  }
}