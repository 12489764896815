
.SettingRegularPage {
  padding: 20px 10px;
  background-color: var(--backgroundDefault);
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: grid;
    grid-template-rows: 80px 1fr;
    gap: 20px;
    @media screen and (max-width: 767px) {
      grid-template-rows: 50px 1fr;
      .title {
        justify-content: center;
        font-size: 22px;
      }
    }
    .title {
      font-weight: 700;
      font-size: 35px;
      line-height: 41px;
      color: var(--colorBlackText);
      display: flex;
      align-items: center;

    }
  }
}