
.MyTask {
  background: #F9FAFB;
  .container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    .task {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 30px;
      @media screen and (max-width: 1100px){
        grid-template-columns: 1fr;
      }
      .taskMain {
        .DoMake {
          font-weight: 700;
          font-size: 35px;
          line-height: 41px;
          color: #212B36;
          //white-space: pre;
          max-height: 200px;
          overflow-y: auto;
        }
        .description {
          background: #FFFFFF;
          box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
          border-radius: 7px;
          margin-top: 30px;
          padding: 20px;
          .descriptionTitle {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #212B36;
            margin-bottom: 10px;
          }
          .descriptionText {
            //white-space: pre;
            max-height: 200px;
            overflow-y: auto;
          }
        }
        .downloadFileListTitle {
          margin-top: 30px;
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          color: var(--colorBlackText);
        }
        @media screen and (max-width: 1100px){
          order: 2;
        }
      }
      .taskInfo {
        width: 300px;
        .status {
          max-width: 620px;
          margin: 0 auto;
          background: #FFFFFF;
          box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
          border-radius: 7px;
          min-height: 60px;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .public {
            color: #00ab55;
            font-weight: bold;
          }
          .notPublic {
            color: #494949;
            font-weight: bold;
          }
        }
        .infoMain {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .price {
            width: 100%;
            max-width: 300px;
            display: flex;
            gap: 20px;
            background: #FFFFFF;
            box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
            border-radius: 7px;
            min-height: 60px;
            padding: 0 20px;
            align-items: center;
            .icon {
              flex: 0 0 34px;
              height: 34px;
              background: #E0E4E8;
              box-shadow: 0px 6px 12px rgba(224, 228, 232, 0.24);
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .minValue {
              font-weight: bold;
            }
            .priceValue {
              font-weight: bold;
              font-size: 18px;
            }
          }
          .deadline {
            width: 100%;
            max-width: 300px;
            display: flex;
            gap: 20px;
            background: #FFFFFF;
            box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
            border-radius: 7px;
            min-height: 60px;
            padding: 0 20px;
            align-items: center;
            .icon {
              flex: 0 0 34px;
              height: 34px;
              background: #E0E4E8;
              box-shadow: 0px 6px 12px rgba(224, 228, 232, 0.24);
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .minValue {
              font-weight: bold;
            }
            .deadlineValue {
              font-weight: bold;
              font-size: 18px;
              span {
                font-weight: 400;
                margin-right: 10px;
              }
            }
          }
        }
        .taskInfoBtnBox {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .changeStatus {
            width: 100%;
            max-width: 300px;
            //margin: 0 auto;
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
            &.disable {
              pointer-events: none;
              opacity: 0.5;
            }
            .notPublicBtn {
              width: 100%;
              height: 100%;
              font-weight: bold;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #FFFFFF;
              box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
              border-radius: 7px;
              min-height: 60px;
              padding: 0 20px;
            }
            .publicBtn {
              width: 100%;
              height: 100%;
              font-weight: bold;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #FFFFFF;
              box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
              border-radius: 7px;
              min-height: 60px;
              padding: 0 20px;
            }
            &:hover {
              background: #efefef;
              cursor: pointer;
            }
          }
          .deleteTask {
            width: 100%;
            max-width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            &.disable {
              pointer-events: none;
              opacity: 0.5;
            }
            .deleteBtn {
              cursor: pointer;
              color: #7a0000;
              &:hover {
                color: #d50202;
                text-decoration: underline;
              }
            }
          }
        }
        @media screen and (max-width: 1100px){
          width: 100%;
          order: 1;
          .infoMain {
            flex-direction: row;
            display: flex;
            justify-content: center;
            gap: 20px;
            flex-wrap: wrap;
          }
          .taskInfoBtnBox {
            flex-direction: row;
            display: flex;
            justify-content: center;
            gap: 20px;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

