
.AnswerTextField {
  position: relative;
  min-height: 150px;
  //.text {
  //  margin-top: 10px;
  //  width: 100%;
  //  padding: 5px 0;
  //  line-height: 1.4;
  //  white-space: pre-wrap;
  //  word-wrap: anywhere;
  //  overflow-x: hidden;
  //  max-height: 100px;
  //  height: 100%;
  //  @media screen and (max-width: 520px){
  //    max-height: 150px;
  //  }
  //  &.changed {
  //    border-bottom: dashed 1px black;
  //  }
  //}
  padding-top: 5px;
  padding-bottom: 30px;
  textarea {
    min-height: 110px;
    max-height: 185px !important;
    width: 100%;
    resize: none;
    //background-color: #84a9ff;

    padding: 15px 15px 0 15px;
  }
  .maxLength {
    position: absolute;
    bottom: 8px;
    right: 15px;
    color: gray;
  }
  .placeHolder {
    position: absolute;
    top: calc(50% - 15px);
    transform: translateY(-50%);
    //height: 100%;
    overflow: hidden ;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    div {
      color: #696969;
      span {
        margin-right: 10px;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #696969;
      }
    }
  }
}