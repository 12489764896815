
.PriceInputField {

  &.notBorder {
    .main {
      margin-top: 0;
      input {
        border-bottom: none;
      }
    }
  }
  .main {
    position: relative;
    margin-top: 10px;
    width: 100%;
    line-height: 1.4;
    white-space: pre-wrap;
    word-wrap: anywhere;
    overflow-x: hidden;
    //overflow: hidden;
    .opacityText {
      pointer-events: none;
      position: absolute;
      overflow: hidden;
      top: 50%;
      transform: translateY(-50%);
      letter-spacing: 0px;
      white-space: nowrap;
      span:first-child {
        opacity: 0;
        letter-spacing: 0;
      }
      span:last-child {
        margin-left: 5px;
        line-height: 20px;
      }
    }

    input {
      border-bottom: dashed 1px black;
      width: calc(100%);
      padding: 5px 0;
      letter-spacing: 0.42px;
    }
  }
  .maxLength {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }
}