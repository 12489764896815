
.Footer {
  background: #EEF0F4;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .container {
    height: 100%;
    max-width: 1200px;
    width: 100%;
    .mainRow {
      margin-top: 50px;
      display: grid;
      grid-template-columns: auto 1fr auto auto;
      //align-items: center;
      justify-content: space-between;
      grid-column-gap: 20px;
      .logo {
        text-decoration: none;
        padding: 0 10px;
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #212B36;
        }
      }
      .navigation {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 24px;
        padding: 0 24px;
        font-weight: 700;
        a {
          max-width: 280px;
          text-decoration: none;
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: #212B36;
          &:first-child {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      .auth, a {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #212B36;
        cursor: pointer;
      }
      .about {
        display: flex;
        //align-items: center;
        justify-content: center;
        gap: 24px;
        padding: 0 24px;
        a {
          text-decoration: none;
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: #212B36;
        }
      }
    }
    .infoRow {
      margin-top: 50px;
      margin-bottom: 50px;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      grid-column-gap: 20px;
      .copyright {
        padding: 0 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #637381;
        cursor: pointer;
      }
      .regulations {
        display: flex;
        gap: 20px;
        padding: 0 20px;
        flex-wrap: wrap;
        justify-content: center;
        .link {
          cursor: pointer;
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
          color: #637381;
        }
      }
      .devSite {
        padding: 0 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 7px;
        color: black;
        .img {
          width: 17px;
          height: 17px;
          display: flex;
          align-items: center;
          svg {
            width: 100%;
          }
        }
        .text {
          font-weight: 400;
          font-size: 12px;
          &:first-child {
            font-weight: 700;
            font-size: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .container {
      .mainRow {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        grid-row-gap: 30px;
        .logo {
          align-self: start;
          .title {
            font-size: 32px;
          }
        }
        .auth {
          grid-column: span 2;
        }
        .about {
          grid-column: span 2;

        }
        .navigation {
          text-align: center;
          flex-wrap: wrap;
        }
      }
      .infoRow {
        margin: 30px auto 20px;
        grid-template-columns: 1fr;
        justify-items: center;
        gap: 10px;
        .copyright {
          order: 3;
        }
        .devSite {
          margin-top: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 520px) {
    .container {
      .mainRow {
        grid-template-columns: 1fr;
        justify-items: start;
        grid-row-gap: 10px;
        .logo {
          margin-top: 20px;
          order: 3;
          justify-self: center;
          .title {
            font-size: 32px;
          }
        }
        .auth {
          margin-top: 20px;
          grid-column: span 1;
          padding-left: 24px;
        }
        .about {
          grid-column: span 1;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

        }
        .navigation {
          text-align: start;
          flex-wrap: wrap;
          flex-direction: column;
          gap: 10px;
        }
      }
      .infoRow {
        margin: 20px auto;
        .regulations {
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    justify-content: flex-start;
    .container {
      .mainRow {
        .logo {
          justify-self: flex-start;
          padding-left: 20px;
        }
      }
      .infoRow {
        margin: 20px auto;
        justify-content: flex-start;
        align-content: flex-start;
        .regulations {
          justify-self: flex-start;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: start;
        }
        .devSite {
          justify-self: flex-start;
        }
        .copyright {
          justify-self: flex-start;
          padding-left: 20px;
        }
      }
    }
  }
}