
.ChatItem {
  margin-bottom: 10px;
  background: #FFFFFF;
  border-radius: 7px;
  padding: 10px 10px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    background-color: rgba(0, 128, 0, 0.07);
  }
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #cfd4d9;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      width: 60%;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    .name, .lastMessage {
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      color: #637381;
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      color: black;
    }
  }
  .update {
    .updateTime {
      font-size: 12px;
    }
    .notReadMessageCount {
      width: 20px;
      height: 20px;
      margin: 10px 0 0 auto;
      background: #00AB55;
      box-shadow: 0px 7.40656px 14.8131px rgba(0, 171, 85, 0.24);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 12px;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}