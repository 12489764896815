
.HomePage {
    background: #F9FAFB;
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    .firstBlock {
      position: relative;
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      min-height: 500px;
      .firstBlockBG {
        width: calc(100%);
        max-width: 1400px;
        height: calc(100% + 100px);
        top: -100px;
        right: 0;
        bottom: 0;
        position: absolute;
        img {
          position: absolute;
          //z-index: -1;
          height: 100%;
          width: 1500px;
          object-fit: contain;
          right: 0;
        }
      }
      .firstBlockInfo {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 34px;
        .text {
          font-weight: 700;
          font-size: 40.1766px;
          line-height: 49px;
          color: #212B36;
          @media screen and (max-width: 1200px) {
            font-size: 34px;
          }
        }
        .showExecutorList {
          background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
          box-shadow: 0px 4px 4px rgba(111, 147, 241, 0.24);
          border-radius: 8.6441px;
          max-width: 300px;
          max-height: 65px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
      .firstBlockImg {
        z-index: 1;

        display: flex;
        align-items: flex-end;
        .imgBox {
          position: relative;
          margin-bottom: -5px;
          img {
            display: block;
            margin: 0;
            width: 100%;
            max-width: 600px;
          }
          .labelTwo {
            bottom: 25%;
            left: 0px;
            position: absolute;
            width: 10%;
            svg {
              width: 100%;
            }
          }
        }
        .label {
          bottom: 60%;
          left: 90%;
          position: absolute;
          background: #DEE7F5;
          height: 40px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 17.0873px;
          line-height: 20px;
          color: #8AADE1;
          @media screen and (max-width: 1025px) {
            display: none;
          }
        }

      }
      @media screen and (max-width: 930px) {
        margin-top: 20px;
        grid-template-columns: 1fr;
        gap: 30px;
        grid-template-rows: 1fr auto;
        .firstBlockBG {
          width: calc(100%);
          max-width: 1400px;
          height: calc(100% - 5px);
          top: 0px;
          right: 0;
          bottom: 0;
          position: absolute;
          img {
            position: absolute;
            //z-index: -1;
            height: 100%;
            width: 1500px;
            object-fit: contain;
            right: 0;
          }
        }
        .firstBlockInfo {
          text-align: center;
          align-items: center;
          .text {
            padding: 0 10%;
            font-weight: 700;
            font-size: 40.1766px;
            line-height: 49px;
            color: #212B36;
            @media screen and (max-width: 1200px) {
              font-size: 34px;
            }
          }
          .showExecutorList {
            height: 50px;
            width: 100%;
          }
        }
        .firstBlockImg {
          .imgBox {
            position: relative;
            margin: 0 auto;
          }
        }
      }
      @media screen and (max-width: 520px) {
        .firstBlockInfo {
          gap: 34px;
          .text {
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            color: #212B36;
          }
        }
      }
    }
    .twoBlock {
      margin-top: 80px;
      .twoBlockTitle {
        margin: 0 auto;
        max-width: 900px;
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        text-align: center;
        color: #212B36;
        @media screen and (max-width: 1200px) {
          font-size: 34px;
        }
        @media screen and (max-width: 520px) {
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          color: #212B36;
        }
      }
      .twoBlockSubTitle {
        margin-top: 20px;
        font-weight: 700;
        font-size: 20px;
        line-height: 143.52%;
        text-align: center;
        letter-spacing: 1px;
        color: #212B36;
        @media screen and (max-width: 1200px) {
          font-size: 18px;
        }
        @media screen and (max-width: 520px) {
          font-weight: 700;
          font-size: 16px;
          color: #212B36;
          line-height: 1.2;
        }
      }
      .placeBlock {
        margin: 35px 10px;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 7px;
        padding: 20px 15px;
        display: grid;
        grid-template-columns: auto 1fr 1fr auto;
        justify-content: center;
        gap: 15px;
        .getMyPlace {
          order: 1;
          width: 270px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 15px;
          .getMyPlaceTitle {
            font-weight: 700;
            font-size: 18px;
            color: #212B36;
          }
          .getMyPlaceBtn {
            display: flex;
            font-weight: 700;
            font-size: 16px;
            color: #00AB55;
            cursor: pointer;
            span {
              padding-left: 8px;
            }
          }
        }
        .setPlace {
          order: 2;
          grid-column: span 2;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 15px;
          .setPlaceTitle {
            font-weight: 700;
            font-size: 18px;
            color: #212B36;
          }

        }
        .searchExecutorFilter {
          margin: 0 15px;
          order: 3;
          justify-self: center;
          align-self: end;
          height: 40px;
          min-width: 150px;
          max-width: 250px;
          width: 100%;
          background: linear-gradient(135deg, #84A9FF 0%, #1939B7 100%);
          box-shadow: 0px 4px 4px rgba(111, 147, 241, 0.24);
          border-radius: 8.6441px;
          font-weight: 700;
          font-size: 15.7215px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        @media screen and (max-width: 991px) {
          grid-template-columns: 1fr 1fr;
          max-width: 600px;
          margin: 35px auto;
          .setPlace {
            order: 1;
          }
          .getMyPlace {
            grid-column: span 2;
            order: 2;
            margin: 15px auto 0;
          }
          .searchExecutorFilter {
            grid-column: span 2;
            margin: 15px auto 0;
          }
        }
        @media screen and (max-width: 500px) {
          padding: 20px 5px;
          .setPlace {
            .setPlaceInputBox {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}