
.VerificationItem {

  .container {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  .backBtn {
    height: 40px;
    padding: 5px;
    font-size: 20px;
    color: var(--colorBlackText);
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
    }
  }
  .title {
    font-weight: 700;
    font-size: 28px;
    color: var(--colorBlackText);
  }

  .main {
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2);
    border-radius: 7px;
    padding: 30px;

    .FieldList {
      max-width: 900px;

      .prof {
        margin-top: 20px;
        .profTitle {
          margin-left: 25px;
          font-weight: 700;
          font-size: 20px;
        }
      }
      .oneItemBox {
        margin-top: 20px;

      }
      .twoItemBox {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
      .field {
        position: relative;

        .fieldTitle {
          position: absolute;
          left: 20px;
          top: -8px;
          background-color: white;
          padding: 0 5px;
          color: var(--colorGreyLightText);
        }
        .fieldMain {
          min-height: 50px;
          width: 100%;
          overflow: hidden;
          border-radius: 8px;
          border: 1px solid rgba(145, 158, 171, 0.32);
          div {
            padding: 10px 10px 10px 20px;
            display: flex;
            align-items: center;
            min-height: 50px;
            width: 100%;
            background-color: #ffffff;
            font-size: 16px;
            color: var(--colorBlackText);
            word-wrap: anywhere;
          }
          .comment {
            min-height: 50px;
            padding: 15px 20px;
            font-size: 16px;
            color: var(--colorBlackText);
          }
        }
      }
    }
    .FileList {
      margin-top: 40px;
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: var(--colorBlackText);
      }
      .loadingFileList {
        min-height: 100px;
        display: flex;
        align-items: center;
      }
      .emptyFileList {
        min-height: 100px;
        display: flex;
        align-items: center;
        color: var(--colorRed);
      }
      .fileList {
        //min-height: 100px;
        display: flex;
        gap: 20px;
        align-items: center;
        .addedFileItem {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 62px;
          height: 80px;
          overflow: hidden;
          background-color: var(--backgroundGrayLite);
          border-radius: 5px;
          padding: 8px;
          cursor: pointer;
          gap: 5px;
          div {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            font-size: 8px;
          }
          .fileSizeText {
            font-weight: bold;
          }
          .fileImgBox {
            justify-self: center;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            background-color: white;
            padding: 3px;
          }
          .upload {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.37);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 60%;
              height: auto;
            }
          }
          .downloadFile {
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.37);
          }
          &:hover {
            .downloadFile {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 26px;
              color: #d7d7d7;
            }
          }
        }
      }

    }
    @media screen and (max-width: 600px) {
      padding: 15px;
    }
  }
  .answerBtnBox {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    div {
      padding: 10px 20px;
      background-color: #ffffff;
      box-shadow: 1px 1px 3px 0px #37353D;
      cursor: pointer;
      border-radius: 10px;
    }
    .access{
      height: 44px;
      min-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
      }
      &:hover {
        background-color: green;
        color: white;
      }
      &.fetching {
        background-color: green;
        color: white;
      }
    }
    .rejected{
      height: 44px;
      min-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
      }
      &:hover {
        background-color: #af0000;
        color: white;
      }
      &.fetching {
        background-color: #af0000;
        color: white;
      }
    }
    @media screen and (max-width: 600px) {
      flex-wrap: wrap ;
    }
  }
}