
.ActiveExecutor {
  padding: 20px 10px;
  background-color: var(--backgroundDefault);

  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    grid-auto-rows: 1fr;
    grid-template-areas:
          'head             head            head'
          'mainBox          mainBox         changeModeBox';
    //'mainBox          mainBox         .';
    align-items: start;
    gap: 40px;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
          'head            head'
          'changeModeBox   changeModeBox'
          'mainBox         mainBox     ';
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas:
          'head'
          'changeModeBox '
          'mainBox       ';
      gap: 25px;
    }
  }
  .mainBox {
    align-self: start;
    grid-area: mainBox;
    display: flex;
    flex-direction: column;
    gap: 30px;
    iframe {
      background: #FFFFFF;
      box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
      border-radius: 7px;
    }
  }
}